import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { DateRange } from "react-day-picker"

import { cn } from "@/lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "./popover"
import "react-day-picker/style.css";

interface DatePickerWithRangeProps {
    className?: string;
    innerClasses?: string;
    fromDate?: Date;
    toDate?: Date;
    onRangeChange: (range: DateRange) => void;
}

export function DatePickerWithRange({
    className,
    innerClasses,
    fromDate,
    toDate,
    onRangeChange,
}: DatePickerWithRangeProps) {
    const [date, setDate_] = React.useState<DateRange>({ from: fromDate, to: toDate })

    const setDate = (date: DateRange) => {
        setDate_(date)
        onRangeChange(date)
    };

    React.useEffect(() => {
        setDate_({ from: fromDate, to: toDate });
    }, [fromDate, toDate])

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id="date"
                    variant={"outline"}
                    className={cn(
                        className,
                        "w-[300px] justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date?.from ? (
                        date.to ? (
                            <>
                                {format(date.from, "LLL dd, y")} -{" "}
                                {format(date.to, "LLL dd, y")}
                            </>
                        ) : (
                            format(date.from, "LLL dd, y")
                        )
                    ) : (
                        <span>Pick a date</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className={cn("w-auto p-0", innerClasses)}
                align="start">
                <Calendar
                    required={true}
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={setDate}
                    numberOfMonths={2}
                />
            </PopoverContent>
        </Popover>
    )
}
