import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import AppPage from './AppPage';

function SomethingWentWrong() {
    const err = useRouteError();
    if (isRouteErrorResponse(err)) {
        if (err.status === 401) {
            return <AppPage active="error">
                <div className="description__container">
                    <h1>Unauthorized</h1>
                    <br />
                    <p>Sorry, you are not authorized to view this page. Please log in and try again.</p>
                </div>
            </AppPage>;
        }
    }
    console.log("Error", err);
    return (
        <AppPage active="error">
            <div className="description__container">
                <h1>Something went wrong</h1>
                <br />
                <p>Sorry, something went wrong. Please reload the page and try again.</p>
                <p>If the problem persists, please contact support.</p>
            </div>
        </AppPage>
    );
}

export default SomethingWentWrong;
