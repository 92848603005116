import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { FaCheck } from "react-icons/fa6"

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={
            "peer h-4 w-4 shrink-0 rounded-sm border text-accent border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground "
            + className
        }
        {...props}
    >
        <CheckboxPrimitive.Indicator
            className={"flex items-center justify-center text-current"}
        >
            <FaCheck className="w-4 h-4" />
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
))
export default Checkbox;
