import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import React from "react";
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"

interface DatePickerProps {
    className?: string;
    selected?: Date;
    onSelect: (date: Date | undefined) => void;
}

export function DatePicker({ className, selected, onSelect }: DatePickerProps) {
    const [open, setOpen] = React.useState(false);
    const closeOnSelect = (date: Date | undefined) => {
        onSelect(date);
        setOpen(false);
    }

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "w-[280px] justify-start text-left font-normal",
                        !selected && "text-muted-foreground",
                        className
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {selected ? format(selected, "PPP") : <span>Pick a selected</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="bg-transparent w-auto p-0">
                <Calendar
                    mode="single"
                    required={false}
                    selected={selected}
                    onSelect={closeOnSelect}
                />
            </PopoverContent>
        </Popover>
    )
}
