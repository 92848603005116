import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    REDO_COMMAND,
    UNDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    FORMAT_TEXT_COMMAND,
    $getSelection,
    $isRangeSelection,
    $createParagraphNode,
    $getNodeByKey,
    LexicalEditor,
    RangeSelection,
    ElementNode,
    $createTextNode,
} from "lexical";
import { $isLinkNode, toggleLink, LinkNode } from "@lexical/link";
import {
    $isParentElementRTL,
    $wrapNodes,
    $isAtNodeEnd
} from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND,
    $isListNode,
    ListNode
} from "@lexical/list";
import {
    $createHeadingNode,
    $createQuoteNode,
    $isHeadingNode
} from "@lexical/rich-text";
import {
    $createCodeNode,
    $isCodeNode,
    getDefaultCodeLanguage,
    getCodeLanguages
} from "@lexical/code";
import { Button } from "react-day-picker";
import { FaEdit, FaExternalLinkAlt, FaRedoAlt, FaUndoAlt } from "react-icons/fa";
import { FaParagraph, FaCode, FaHeading, FaListOl, FaListUl, FaQuoteRight, FaBold, FaItalic, FaUnderline, FaStrikethrough, FaLink, FaTrash } from "react-icons/fa6";
import { Separator } from "@/components/ui/separator";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdownmenu";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { PopoverClose } from "@radix-ui/react-popover";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Link } from "react-router-dom";

const LowPriority = 1;

const supportedBlockTypes = new Set([
    "paragraph",
    "quote",
    "code",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "ul",
    "ol"
]);

const Heading1 = <span className="flex items-center w-6"><FaHeading />1</span>;
const Heading2 = <span className="flex items-center w-6"><FaHeading />2</span>;
const Heading3 = <span className="flex items-center w-6"><FaHeading />3</span>;
const Heading4 = <span className="flex items-center w-6"><FaHeading />4</span>;
const Heading5 = <span className="flex items-center w-6"><FaHeading />5</span>;
const Heading6 = <span className="flex items-center w-6"><FaHeading />6</span>;

const blockTypeToBlockDisplay: Record<string, React.ReactNode> = {
    paragraph: <FaParagraph className="w-6 mt-1" />,
    quote: <FaQuoteRight className="w-6 mt-1" />,
    code: <FaCode className="w-6 mt-1" />,
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    h4: Heading4,
    h5: Heading5,
    h6: Heading6,
    ul: <FaListUl className="w-6 mt-1" />,
    ol: <FaListOl className="w-6 mt-1" />,
};

const LinkSchema = z.object({
    link_text: z.string(),
    link_url: z.string(),
});

const SUPPORTED_URL_PROTOCOLS = new Set([
    'http:',
    'https:',
    'mailto:',
    'sms:',
    'tel:',
]);

function sanitizeUrl(url: string): string {
    try {
        const parsedUrl = new URL(url);
        // eslint-disable-next-line no-script-url
        if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
            return 'about:blank';
        }
    } catch {
        return url;
    }
    return url;
}

function LinkPopover({ editor, selectedText }: { editor: LexicalEditor, selectedText: string }) {
    const form = useForm<z.infer<typeof LinkSchema>>({
        resolver: zodResolver(LinkSchema),
        defaultValues: {
            link_text: selectedText,
            link_url: "",
        }
    });

    const onSubmitLinkForm = (data: z.infer<typeof LinkSchema>) => {
        const { link_text, link_url } = data;
        insertLink(link_text, sanitizeUrl(link_url));
    }

    const insertLink = useCallback((linkText: string, linkUrl: string) => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const { anchor, focus } = selection;
                selection.insertText(linkText);
                anchor.offset -= linkText.length;
                focus.offset = anchor.offset + linkText.length;
                toggleLink(linkUrl);
            }
        });
    }, [editor]);

    return (
        <Popover>
            <PopoverTrigger>
                <Button
                    className="toolbar-item spaced"
                    aria-label="Insert link"
                >
                    <FaLink />
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <div className="grid gap-4">
                    <Form {...form}>
                        <form className="grid gap-2" onSubmit={form.handleSubmit(onSubmitLinkForm)}>
                            <div className="space-y-2">
                                <h4 className="font-medium leading-none">Insert Link</h4>
                                <p className="text-sm text-muted-foreground">
                                    Enter the URL for the link
                                </p>
                            </div>
                            <FormField
                                control={form.control}
                                name="link_text"
                                render={({ field }) => (<FormItem>
                                    <FormLabel htmlFor="link_text">Text</FormLabel>
                                    <FormControl>
                                        <Input className="col-span-2 h-8" {...field} />
                                    </FormControl>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="link_url"
                                render={({ field }) => (<FormItem>
                                    <FormLabel htmlFor="link_url">URL</FormLabel>
                                    <FormControl>
                                        <Input className="col-span-2 h-8" {...field} />
                                    </FormControl>
                                </FormItem>)}
                            />
                            <PopoverClose>
                                <Button type="submit" className="btn grid-cols-3 w-full">Insert</Button>
                            </PopoverClose>
                        </form>
                    </Form>
                </div>
            </PopoverContent>
        </Popover>
    );
}

interface LinkEditorPopoverProps {
    editor: LexicalEditor;
    link: LinkNode;
}

const LinkEditorPopover = ({ editor, link }: LinkEditorPopoverProps) => {
    const [linkUrl, setLinkUrl] = useState("");
    const form = useForm<z.infer<typeof LinkSchema>>({
        resolver: zodResolver(LinkSchema),
        defaultValues: {
            link_text: "",
            link_url: "",
        }
    })

    useEffect(() => {
        editor.read(() => {
            const linkUrlValue = link.getURL();
            const linkText = link.getTextContent();
            setLinkUrl(linkUrlValue);
            form.setValue("link_text", linkText);
            form.setValue("link_url", linkUrlValue);
        });
    }, [editor, link, form]);

    const onSubmitLinkForm = (data: z.infer<typeof LinkSchema>) => {
        const { link_text, link_url } = data;
        editor.update(() => {
            link.setURL(link_url);
            link.append($createTextNode(link_text));
            link.getFirstChild()?.remove();
        });
    }

    const deleteLink = () => {
        editor.update(() => {
            link.remove();
        });
    }

    return (
        <Popover>
            <PopoverTrigger>
                <Button
                    className="toolbar-item spaced"
                    aria-label="Edit link"
                ><FaEdit /></Button>
            </PopoverTrigger>
            <PopoverContent>
                <div className="grid gap-4">
                    <Form {...form}>
                        <form className="grid gap-2 grid-cols-3 items-center" onSubmit={form.handleSubmit(onSubmitLinkForm)}>
                            <FormField
                                control={form.control}
                                name="link_text"
                                render={({ field }) => (<FormItem className="col-span-3">
                                    <FormLabel htmlFor="link_text">Text</FormLabel>
                                    <FormControl>
                                        <Input className="col-span-2 h-8" {...field} />
                                    </FormControl>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="link_url"
                                render={({ field }) => (<FormItem className="col-span-3">
                                    <FormLabel htmlFor="link_url">URL</FormLabel>
                                    <FormControl>
                                        <Input className="col-span-2 h-8" {...field} />
                                    </FormControl>
                                </FormItem>)}
                            />
                            <Link target="_blank" className="m-auto" to={linkUrl}><FaExternalLinkAlt /></Link>
                            <PopoverClose>
                                <Button type="submit" className="btn">Update</Button>
                            </PopoverClose>
                            <PopoverClose>
                                <Button onClick={deleteLink} className="text-red-500 dark:text-hellotrope-400"><FaTrash /></Button>
                            </PopoverClose>
                        </form>
                    </Form>
                </div>
            </PopoverContent>
        </Popover>
    );
}

interface SelectProps {
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    className: string;
    options: string[];
    value: string;
}

function Select({ onChange, className, options, value }: SelectProps) {
    return (
        <select className={className} onChange={onChange} value={value}>
            <option hidden={true} value="" />
            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

function getSelectedNode(selection: RangeSelection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}

interface BlockOptionsDropdownListProps {
    editor: LexicalEditor;
    blockType: string;
}

function BlockOptionsDropdownList({ editor, blockType, }: BlockOptionsDropdownListProps) {

    const formatter = (blockTypeTransform: string, createNodeFn: () => ElementNode) => {
        return () => {
            if (blockType !== blockTypeTransform) {
                editor.update(() => {
                    const selection = $getSelection();

                    if ($isRangeSelection(selection)) {
                        $wrapNodes(selection, () => createNodeFn());
                    }
                });
            }
        };
    }

    const formatBulletList = () => {
        if (blockType !== "ul") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "ol") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    };

    const formatParagraph = formatter("paragraph", $createParagraphNode);
    const formatH1 = formatter("h1", () => $createHeadingNode("h1"));
    const formatH2 = formatter("h2", () => $createHeadingNode("h2"));
    const formatH3 = formatter("h3", () => $createHeadingNode("h3"));
    const formatH4 = formatter("h4", () => $createHeadingNode("h4"));
    const formatH5 = formatter("h5", () => $createHeadingNode("h5"));
    const formatH6 = formatter("h6", () => $createHeadingNode("h6"));
    const formatQuote = formatter("quote", $createQuoteNode);
    const formatCode = formatter("code", $createCodeNode);

    return (
        <DropdownMenu>
            <DropdownMenuTrigger>
                <Button className="mt-1" aria-label="Formatting Options">
                    {blockTypeToBlockDisplay[blockType]}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuGroup className="flex flex-col">
                    <DropdownMenuItem className="item" onClick={formatParagraph}>
                        <FaParagraph className="w-6" />
                        <span className="text">Normal</span>
                        {blockType === "paragraph" && <span className="active" />}
                    </DropdownMenuItem>
                    <DropdownMenuItem className="item" onClick={formatBulletList}>
                        <FaListUl className="w-6" />
                        <span className="text">List</span>
                        {blockType === "ul" && <span className="active" />}
                    </DropdownMenuItem>
                    <DropdownMenuItem className="item" onClick={formatNumberedList}>
                        <FaListOl className="w-6" />
                        <span className="text">Ordered List</span>
                        {blockType === "ol" && <span className="active" />}
                    </DropdownMenuItem>
                    <DropdownMenuItem className="item" onClick={formatQuote}>
                        <FaQuoteRight className="w-6" />
                        <span className="text">Quote</span>
                        {blockType === "quote" && <span className="active" />}
                    </DropdownMenuItem>
                    <DropdownMenuItem className="item" onClick={formatCode}>
                        <FaCode className="w-6" />
                        <span className="text">Code Block</span>
                        {blockType === "code" && <span className="active" />}
                    </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuGroup className="flex flex-col">
                    <Button className="item" onClick={formatH1}>
                        {Heading1}
                        <span className="text">Heading 1</span>
                        {blockType === "h1" && <span className="active" />}
                    </Button>
                    <Button className="item" onClick={formatH2}>
                        {Heading2}
                        <span className="text">Heading 2</span>
                        {blockType === "h2" && <span className="active" />}
                    </Button>
                    <Button className="item" onClick={formatH3}>
                        {Heading3}
                        <span className="text">Heading 3</span>
                        {blockType === "h3" && <span className="active" />}
                    </Button>
                    <Button className="item" onClick={formatH4}>
                        {Heading4}
                        <span className="text">Heading 4</span>
                        {blockType === "h4" && <span className="active" />}
                    </Button>
                    <Button className="item" onClick={formatH5}>
                        {Heading5}
                        <span className="text">Heading 5</span>
                        {blockType === "h5" && <span className="active" />}
                    </Button>
                    <Button className="item" onClick={formatH6}>
                        {Heading6}
                        <span className="text">Heading 6</span>
                        {blockType === "h6" && <span className="active" />}
                    </Button>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

export default function ToolbarPlugin({ children }: { children: React.ReactNode }) {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [blockType, setBlockType] = useState("paragraph");
    const [selectedElementKey, setSelectedElementKey] = useState<null | string>(null);
    const [codeLanguage, setCodeLanguage] = useState("");
    const [, setIsRTL] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [linkNode, setLinkNode] = useState<LinkNode | null>(null);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isCode, setIsCode] = useState(false);
    const [selectedText, setSelectedText] = useState("");

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type);
                    if ($isCodeNode(element)) {
                        setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
                    }
                }
            }
            setSelectedText(element.getTextContent());

            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsCode(selection.hasFormat("code"));
            setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setBlockType("link");
                setIsLink(true);
                setLinkNode(parent);
            } else if ($isLinkNode(node)) {
                setBlockType("link");
                setIsLink(true);
                setLinkNode(node);
            } else {
                setIsLink(false);
                setLinkNode(null);
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    const codeLanguages = useMemo(() => getCodeLanguages(), []);
    const onCodeLanguageSelect = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            editor.update(() => {
                if (selectedElementKey !== null) {
                    const node = $getNodeByKey(selectedElementKey);
                    if ($isCodeNode(node)) {
                        node.setLanguage(e.target.value);
                    }
                }
            });
        },
        [editor, selectedElementKey]
    );

    return (
        <div className="toolbar items-center" ref={toolbarRef}>
            <Button
                disabled={!canUndo}
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND, undefined);
                }}
                className="toolbar-item spaced"
                aria-label="Undo"
            >
                <FaUndoAlt />
            </Button>
            <Button
                disabled={!canRedo}
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND, undefined);
                }}
                className="toolbar-item"
                aria-label="Redo"
            >
                <FaRedoAlt />
            </Button>
            <Separator orientation="vertical" />
            {supportedBlockTypes.has(blockType) && (
                <>
                    <BlockOptionsDropdownList blockType={blockType} editor={editor} />
                    <Separator orientation="vertical" />
                </>
            )}
            {blockType === "code" ? (
                <>
                    <div className="flex-grow" />
                    <Select
                        className="toolbar-item code-language dark:bg-neutral-800"
                        onChange={onCodeLanguageSelect}
                        options={codeLanguages}
                        value={codeLanguage}
                    />
                </>
            ) : (
                <>
                    <div className="flex-grow" />
                    <Button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                        }}
                        className={"toolbar-item spaced " + (isBold ? "active" : "")}
                        aria-label="Format Bold"
                    >
                        <FaBold className="w-6" />
                    </Button>
                    <Button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                        }}
                        className={"toolbar-item spaced " + (isItalic ? "active" : "")}
                        aria-label="Format Italics"
                    >
                        <FaItalic className="w-6" />
                    </Button>
                    <Button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                        }}
                        className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
                        aria-label="Format Underline"
                    >
                        <FaUnderline className="w-6" />
                    </Button>
                    <Button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                        }}
                        className={
                            "toolbar-item spaced " + (isStrikethrough ? "active" : "")
                        }
                        aria-label="Format Strikethrough"
                    >
                        <FaStrikethrough className="w-6" />
                    </Button>
                    <Button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                        }}
                        className={"toolbar-item spaced " + (isCode ? "active" : "")}
                        aria-label="Insert Code"
                    >
                        <FaCode className="w-6" />
                    </Button>
                    <LinkPopover editor={editor} selectedText={selectedText} />
                    {isLink && linkNode && <LinkEditorPopover
                        editor={editor}
                        link={linkNode} />}
                </>
            )}
            <div className="flex-grow" />
            {children}
        </div>
    );
}
