import React, { useState, useCallback, useEffect } from "react";

import { EditableRegion, EditForm, FormElement } from "@/components/cards/EditableDropdown";
import { ScrollArea } from "@/components/ui/scrollarea";
import { Button } from "@/components/ui/button";

interface DetailControlProps {
    id?: string;
    icon: React.ReactElement;
    text: string;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
}

export function DetailControl({ id, icon, text, className, onClick, disabled }: DetailControlProps) {
    const extraClass = className ? " " + className : "";
    return (
        <Button
            disabled={disabled}
            id={id}
            className={"detail-grid__control_button m-1" + extraClass}
            onClick={onClick}
        >
            {icon}
            <div className="detail-grid__control_button_text">&nbsp;{text}</div>
        </Button>
    );
}

interface DetailControlMenuProps {
    icon: React.ReactElement;
    controlText: string;
    buttonText: string;
    initialElements: FormElement[]
    onSubmit: (elements: FormElement[]) => void;
    shouldOpen?: boolean;
    onOpen?: () => void;
}

export function DetailControlMenu({
    icon,
    controlText,
    buttonText,
    initialElements,
    onSubmit,
    shouldOpen,
    onOpen
}: DetailControlMenuProps) {
    const [menuOpen, setMenuOpen] = useState(false);
    const handleButtonClick = useCallback(() => {
        if (menuOpen) {
            setMenuOpen(false);
        } else {
            setMenuOpen(true);
            if (onOpen) {
                onOpen();
            }
        }
    }, [setMenuOpen, menuOpen, onOpen]);

    useEffect(() => {
        if (menuOpen && shouldOpen === false) {
            setMenuOpen(false);
        }
    }, [shouldOpen, menuOpen, setMenuOpen]);

    const onSave = useCallback((elements: FormElement[]) => {
        onSubmit(elements);
        setMenuOpen(false);
    }, [onSubmit, setMenuOpen]);

    return (<div>
        <DetailControl
            icon={icon}
            text={controlText}
            onClick={handleButtonClick}
        />
        <EditableRegion isEditing={menuOpen}>
            <EditForm
                initialElements={initialElements}
                buttonText={buttonText}
                onSave={onSave}
            />
        </EditableRegion>
    </div>);
}

interface DetailControlsProps {
    id?: string;
    children: React.ReactNode;
}

export function DetailControls({ id, children }: DetailControlsProps) {
    return <div id={id} className="detail-grid__controls">{children}</div>;
}

interface DetailSidePanelProps {
    id?: string;
    children: React.ReactNode;
}

export function DetailSidePanel({ id, children }: DetailSidePanelProps) {
    return <ScrollArea id={id} className="detail-grid__details">
        {children}
    </ScrollArea>;
}

interface DetailContentAreaProps {
    id?: string;
    children: React.ReactNode;
    className?: string;
}

export function DetailContentArea({ id, children, className }: DetailContentAreaProps) {
    const extraClass = className ? " " + className : "";
    return <ScrollArea id={id} className={"detail-grid__content" + extraClass}>{children}</ScrollArea>;
}

interface DetailGridProps {
    children: React.ReactNode;
    narrow?: boolean;
}

function DetailGrid({ children, narrow }: DetailGridProps) {
    const className = narrow ? "detail-grid--narrow" : "detail-grid";
    return <div className={className}>{children}</div>;
}

export default DetailGrid;
