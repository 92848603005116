import { useCallback, useEffect, useState } from "react";
import { FaXmark, FaDiagramProject, FaFileImport } from "react-icons/fa6";
import { FaArchive } from "react-icons/fa";
import { MdOutlineSwitchAccessShortcut } from "react-icons/md";
import DetailGrid, {
    DetailContentArea,
    DetailControl,
    DetailControls,
    DetailSidePanel,
} from "../DetailGrid";
import { useNavigate } from "react-router-dom";

import Editor from "@/components/Editor";
import DetailCard from "@/components/cards/DetailCard";
import Spinner from "@/components/Spinner";
import { Button } from "@/components/ui/button";
import { Description } from "@radix-ui/react-dialog";
import { Dialog, DialogClose, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import Floater, { InfoIcon } from "@/components/Floater";
import { Project, Repo, ImportUpdate, ImportState, ImportStateInfo } from "@/contexts/Project";
import { Separator } from "@/components/ui/separator";
import { apiFetch, eventEmitter, useProjectsContext, useUsersContext } from "@/contexts";
import { testUuid } from "@/tools";
import FileUpload from "@/components/FileUpload";
import ShareButton from "@/components/ShareButton";

interface ProjectRepoProps {
    repo: Repo;
    projectId: string;
    onRemove: (repoId: string) => void;
}

async function updateRepo(projectId: string, repoId: string, update: object) {
    return await apiFetch(`/api/v1/project/${projectId}/import/${repoId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(update)
    });
}

function ProjectRepo({ repo, projectId, onRemove }: ProjectRepoProps) {
    const displayState = (state: ImportState) => {
        if (typeof state === "object" && "inProgress" in state) {
            return state.inProgress;
        } else if (typeof state === "object" && "paused" in state) {
            return "paused";
        }
        return state;
    };

    const displayInfo = (info: ImportStateInfo | undefined) => {
        if (typeof info === "object") {
            if ("issues" in info) {
                return `(${info.issues.issues} issues)`;
            } else if ("comments" in info) {
                return `(${info.comments.total} issues)`;
            } else if ("portraits" in info) {
                return `(${info.portraits.total} users)`;
            }
        }
        return null;
    };
    const info = displayInfo(repo.importState?.info);
    const done = repo.importState && (repo.importState.state === "done" || repo.importState.state === "error");

    const startImport = () => {
        updateRepo(projectId, repo.id, { state: "start" }).then();
    };

    const pauseImport = () => {
        updateRepo(projectId, repo.id, { state: "pause" }).then();
    };

    const onDialogConfirmDelete = () => {
        onRemove(repo.id);
    }

    return <div className="mt-4 p-2 grid grid-gap-2 grid-cols-3">
        <h2 className="col-span-2 font-bold">Repository</h2>
        <Dialog>
            <DialogTrigger>
                <FaXmark className="col-span-1 ml-auto mr-6" />
            </DialogTrigger>
            <DialogContent>
                <DialogTitle>Delete repository</DialogTitle>
                <Description className="editable__label text-center">Are you sure you want to delete this import sync?</Description>
                <DialogClose className="flex">
                    <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black" onClick={onDialogConfirmDelete}>Yes</Button>
                    <Button className="editable__button">No</Button>
                </DialogClose>
            </DialogContent>
        </Dialog>

        <div className="flex mr-4 col-span-3">
            <label className="editable__label flex-grow">Tracker</label>
            <label className="editable__text">{repo.tracker}</label>
        </div>
        <div className="flex mr-4 col-span-3">
            <label className="editable__label flex-grow">Origin</label>
            <label className="editable__text">{repo.repoGitRemote}</label>
        </div>
        {repo.repoWebUrl && (<div className="flex text-right col-span-3">
            <a href={repo.repoWebUrl} className="flex-grow text-right" target="_blank" rel="noreferrer">
                <label className="editable__link">Website</label>
            </a>
        </div>)}
        <div className="flex col-span-3 mr-6">
            <label className="editable__label flex-grow">Default</label>
            <input type="checkbox"
                className="editable__text"
                checked={repo.isDefault}
                disabled={true} />
        </div>
        {repo.importState ? <>
            <div className="flex col-span-3 mr-4">
                <label className="editable__label">Importing</label>
                <div className="flex-grow text-right">
                    <label className="editable__text flex-grow">{displayState(repo.importState.state)}</label>
                    {info && <label className="ml-2 editable__text flex-grow">{info}</label>}
                </div>
            </div>
            {!done ? <>
                <Spinner className="col-span-3" />
                <Button className="btn col-span-3" onClick={pauseImport}>Pause</Button>
            </> : repo.importState.error ? <>
                <h6 className="editable__text col-span-3 mr-4 text-rufous-600 dark:text-hellotrope-400">{repo.importState.error}</h6>
                <InfoIcon content="The import will pull in all issues, comments, and users from the repository." />
                <Button className="btn mr-4" onClick={startImport}>Import</Button>
                <div />
            </> : <>
            </>}
        </> : <>
            <InfoIcon content="The import will pull in all issues, comments, and users from the repository." />
            <Button className="btn mr-4" onClick={startImport}>Import</Button>
            <div />
        </>}
        <Separator className="col-span-3 mt-4 mb-4" />
    </div>;
}

interface ProjectPanelProps {
    projectData: Project;
}

function ProjectPanel({ projectData }: ProjectPanelProps) {
    const navigate = useNavigate();
    const { orgProjects, setActiveProject, updateProject, moveProjectToOrg, deleteProject, clearProjectCache } = useProjectsContext();
    const { users } = useUsersContext();

    const isOrgProject = orgProjects.find((item) => item.id === projectData.id) !== undefined;
    const [openMoveModal, setMoveModalOpen] = useState(false);
    const [openDeleteModal, setDeleteModalOpen] = useState(false);

    const onSave = useCallback((markdown: string) => {
        updateProject(projectData.id, { description: markdown });
    }, [updateProject, projectData.id]);

    const handleActivate = useCallback(() => {
        setActiveProject(projectData);
        navigate('/app/tasks');
    }, [projectData, setActiveProject, navigate]);

    const handleRmRepo = useCallback((repoId: string) => {
        const values = { deleteRepo: { repoId } };
        updateProject(projectData.id, values);
        clearProjectCache();
    }, [projectData.id, updateProject, clearProjectCache]);

    const repos = projectData.repos;

    useEffect(() => {
        const handleImportUpdate = ({ update }: { update: ImportUpdate }) => {
            if (update.projectId === projectData.id) {
                const repo = repos.find((repo) => repo.id === update.projectTrackerId);
                if (repo) {
                    repo.importState = {
                        state: update.state,
                        error: update.error,
                        info: update.info
                    };
                }
            }
        }
        eventEmitter.on('IMPORT-UPDATE', handleImportUpdate);
        return () => {
            eventEmitter.off('IMPORT-UPDATE', handleImportUpdate);
        }
    }, [projectData, repos]);

    const [narrow, setNarrow] = useState(window.innerWidth < 1000);

    useEffect(() => {
        const resizeListener = () => {
            setNarrow(window.innerWidth < 1000);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const DetailContentMode = !narrow ? (
        <DetailContentArea>
            <h2>Description</h2>
            <Editor
                markdown={projectData.description}
                placeholder="Describe your project..."
                users={users}
                onSaveMarkdown={onSave}
            />
        </DetailContentArea>
    ) : (
        <DetailContentArea className="flex-col">
            {repos && repos.map((repo, index) => (
                <ProjectRepo
                    key={index}
                    repo={repo}
                    projectId={projectData.id}
                    onRemove={handleRmRepo}
                />
            ))}
            <h2>Description</h2>

            <Editor
                markdown={projectData.description}
                placeholder="Describe your project..."
                users={users}
                onSaveMarkdown={onSave}
            />
        </DetailContentArea>
    );
    const DetailSidePanelMode = !narrow ? (
        <DetailSidePanel>
            <div>
                {repos && repos.map((repo, index) => (
                    <ProjectRepo
                        key={index}
                        repo={repo}
                        projectId={projectData.id}
                        onRemove={handleRmRepo}
                    />
                ))}
            </div>
        </DetailSidePanel>
    ) : <div />;

    return (
        <DetailGrid narrow={narrow}>
            <DetailControls>
                <DetailControl
                    id="openProjectButton"
                    icon={<MdOutlineSwitchAccessShortcut />}
                    text="Open Project"
                    onClick={handleActivate}
                />
                {!isOrgProject && <DetailControl
                    icon={<FaFileImport />}
                    text="Move to Team"
                    onClick={() => setMoveModalOpen(true)} />}
                <Dialog open={openMoveModal} onOpenChange={setMoveModalOpen}>
                    <DialogContent>
                        <DialogTitle>Move project</DialogTitle>
                        <Description>Are you sure you want to move this project to the team?</Description>
                        <DialogClose className="flex">
                            <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black" onClick={() => moveProjectToOrg(projectData)}>Yes</Button>
                            <Button className="editable__button">No</Button>
                        </DialogClose>
                    </DialogContent>
                </Dialog>
                <FileUpload uploadPath={`/api/v1/project/${projectData.id}/import`} className="detail-grid__control_button m-1" />
                <Floater content="Copy the link for this task">
                    <ShareButton className="detail-grid__control_button m-1" />
                </Floater>

                <div className="flex-grow" />
                <DetailControl
                    icon={<FaArchive />}
                    text="Delete Project"
                    onClick={() => setDeleteModalOpen(true)}
                />
                <Dialog open={openDeleteModal} onOpenChange={setDeleteModalOpen}>
                    <DialogContent>
                        <DialogTitle>Delete project</DialogTitle>
                        <Description>Are you sure you want to delete this project?</Description>
                        <DialogClose className="flex">
                            <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black"
                                onClick={() => deleteProject(projectData.id)}>Yes</Button>
                            <Button className="editable__button">No</Button>
                        </DialogClose>
                    </DialogContent>
                </Dialog>
            </DetailControls>
            {DetailSidePanelMode}
            {DetailContentMode}
        </DetailGrid>
    );
}

interface ProjectDetailProps {
    id: string
}

function ProjectDetail({ id }: ProjectDetailProps) {
    const { projects, orgProjects, updateProject, getProject } = useProjectsContext();
    const [project, setProject] = useState<Project | null>(null);

    useEffect(() => {
        if (testUuid(id)) {
            getProject(id).then(setProject);
        } else {
            setProject(orgProjects.find((item) => item.name == id) ||
                projects.find((item) => item.name == id) ||
                null);
        }
    }, [id, projects, orgProjects, getProject]);

    const navigate = useNavigate();

    const vanish = () => {
        navigate(-1);
    };

    function onChangeName(name: string) {
        if (project && name != project.name && name) {
            updateProject(project.id, { name: name });
        }
    }

    return (
        <DetailCard
            title={project?.name}
            titleIcon={<FaDiagramProject />}
            editable={true}
            onSetTitle={onChangeName}
            onClose={vanish}>
            {project && <ProjectPanel projectData={project} />}
        </DetailCard>
    );
}

export default ProjectDetail;
