import React, { useEffect } from "react";
import AppPage from "./AppPage";
import { AssignmentChange, CommentChange, fetchNotifications, InviteNotification, markAllNotificationsRead, Notification, TaskChange } from "./Notification";
import { useMilestoneContext, useProjectsContext, useUsersContext } from "@/contexts";
import { fetchTask, useTask } from "@/contexts/Task";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";
import { priorityNameFromPriorityValues, priorityValuesToPriorityArray } from "./cards/task/Priority";

const InviteNotification: React.FC<{ message: InviteNotification, created: string, read: boolean }> = ({ message, created, read }) => {
    const date = new Date(created + "Z");
    const highlightClass = read ? "notification__item" : "notification__item notification__item--highlight";

    return (
        <div className={highlightClass}>
            <div className="notification__message">
                You have been invited to join {message.orgName} by {message.invitedBy.username}
            </div>
            <ReactTimeAgo className="ml-2 text-sm" date={date} />
        </div>
    );
}

interface TaskChangeNotificationProps {
    update: { update: TaskChange } | { assignment: AssignmentChange } | { comment: CommentChange };
    created: string;
    read: boolean;
}

interface TaskNotificationData {
    taskId: string;
    from: string;
}

type TaskMessage = { update: TaskChange } | { assignment: AssignmentChange } | { comment: CommentChange };
function getTaskDataFromNotification(message: TaskMessage): TaskNotificationData {
    if ("update" in message) {
        const data = message.update;
        return { taskId: data.taskId, from: data.from };
    } else if ("assignment" in message) {
        const data = message.assignment;
        return { taskId: data.taskId, from: data.from };
    } else if ("comment" in message) {
        const data = message.comment;
        return { taskId: data.taskId, from: data.from };
    }
    throw new Error("Invalid notification message");
}

const TaskChangeNotification: React.FC<TaskChangeNotificationProps> = ({ created, update, read }) => {
    const date = new Date(created + "Z");
    const highlightClass = read ? "notification__item" : "notification__item notification__item--highlight";
    const { taskId, from: userId } = getTaskDataFromNotification(update);
    const { data: taskDetail, isError, isLoading } = useTask(taskId);
    const task = taskDetail?.task;
    const { milestones } = useMilestoneContext();
    const { priorityValues } = useProjectsContext();
    const { users } = useUsersContext();

    const [message, setMessage] = React.useState<React.ReactNode | null>(null);
    const [link, setLink] = React.useState<string | null>(null);

    useEffect(() => {
        if (!task || isError || isLoading) {
            return;
        }
        const user = users.find((user) => user.id === userId);
        if (!user) {
            return;
        }

        setLink("/app/tasks/id/" + taskId);

        if ("assignment" in update) {
            setMessage(<span>{user.username} assigned you to {task.title}</span>);
        } else if ("comment" in update) {
            setMessage(<span>{user?.username} commented on {task?.title}</span>);
        } else if ("update" in update) {
            const updddate = update.update.update;
            if (typeof updddate === "object" && "assignOther" in updddate) {
                const userId = updddate.assignOther.userId;
                const user = users.find((user) => user.id === userId);
                setMessage(<span>{user?.username} assigned {task.slug} to {user?.username}</span>);
            } else if (typeof updddate === "object" && "changeDescription" in updddate) {
                setMessage(<span>{user?.username} modified the description for {task.slug}</span>);
            } else if (typeof updddate === "object" && "changeTitle" in updddate) {
                setMessage(<span>{user?.username} set the title of {task.slug} to {updddate.changeTitle.title}</span>);
            } else if (typeof updddate === "object" && "changePriority" in updddate) {
                if (priorityValues) {
                    const priorities = priorityValuesToPriorityArray(priorityValues);
                    const name = priorityNameFromPriorityValues(updddate.changePriority.value, priorities);
                    setMessage(<span>{user?.username} changed the priority of {task.slug} to {name}</span>);
                }
            } else if (typeof updddate === "object" && "changeDueDate" in updddate) {
                if (updddate.changeDueDate.dueDate === null) {
                    setMessage(<span>{user?.username} removed the due date of {task.slug}</span>);
                } else {
                    const date = new Date(updddate.changeDueDate.dueDate);
                    setMessage(
                        <>
                            <div>{user?.username}</div>
                            <span>set the due date of {task.slug} to </span>
                            <ReactTimeAgo className="ml-2" date={date} />
                        </>
                    );
                }
            } else if (typeof updddate === "object" && "changeMilestone" in updddate) {
                if (updddate.changeMilestone.milestoneId) {
                    const milestone = milestones.find(m => m.id === updddate.changeMilestone.milestoneId);
                    setMessage(<span>{user?.username} changed the milestone of {task.slug} to {milestone?.name}</span>);
                } else {
                    setMessage(<span>{user?.username} removed the milestone from {task.slug}</span>);
                }
            } else if (typeof updddate === "object" && "addComment" in updddate) {
                if (updddate.addComment.parentId) {
                    setMessage(<span>{user?.username} replied to a comment on {task.slug}</span>);
                } else {
                    setMessage(<span>{user?.username} added a comment to {task.slug}</span>);
                }
            } else if (typeof updddate === "object" && "editComment" in updddate) {
                setMessage(<span>{user?.username} edited a comment on {task.slug}</span>);
            } else if (typeof updddate === "object" && "removeComment" in updddate) {
                setMessage(<span>{user?.username} deleted a comment from {task.slug}</span>);
            } else if (typeof updddate === "object" && "link" in updddate) {
                fetchTask(updddate.link.taskId).then((linkedTask) => {
                    setMessage(<span>{user?.username} linked {task.slug} to task {linkedTask?.task.slug} ({updddate.link.linkType})</span>);
                });
            } else if (typeof updddate === "object" && "unlink" in updddate) {
                fetchTask(updddate.unlink.taskId).then((linkedTask) => {
                    setMessage(<span>{user?.username} unlinked {task.slug} from task {linkedTask?.task.slug}</span>);
                });
            } else if (typeof updddate === "object" && "metadata" in updddate) {
                if ("component" in updddate.metadata.value) {
                    setMessage(<span>{user?.username} added component {updddate.metadata.value.component as string} from {task.slug}</span>);
                } else if ("label" in updddate.metadata.value) {
                    setMessage(<span>{user?.username} removed tag {updddate.metadata.value.label as string} from {task.slug}</span>);
                }
            } else if (typeof updddate === "object" && "removeMetadata" in updddate) {
                if ("component" in updddate.removeMetadata.value) {
                    setMessage(<span>{user?.username} removed component {updddate.removeMetadata.value.component as string} from {task.slug}</span>);
                } else if ("label" in updddate.removeMetadata.value) {
                    setMessage(<span>{user?.username} removed tag {updddate.removeMetadata.value.label as string} from {task.slug}</span>);
                }
            } else if (typeof updddate === "object" && "transition" in updddate) {
                setMessage(<span>{user?.username} transitioned {task.slug} to in progress</span>);
            } else if (typeof updddate === "object" && "stopWatchingTask" in updddate) {
                setMessage(<span>{user?.username} stopped watching {task.slug}</span>);
            } else if (typeof updddate === "object" && "watchTask" in updddate) {
                setMessage(<span>{user?.username} watching {task.slug}</span>);
            } else if (typeof updddate === "string") {
                switch (updddate) {
                    case "assignSelf":
                        setMessage(<span>{user?.username} assigned themself to {task.slug}</span>);
                        break;
                    case "close":
                        setMessage(<span>{user?.username} closed {task.slug}</span>);
                        break;
                    case "restart":
                        setMessage(<span>{user?.username} reopened {task.slug}</span>);
                        break;
                    case "archive":
                        setMessage(<span>{user?.username} archived {task.slug}</span>);
                        break;
                    case "unassign":
                        setMessage(<span>{user?.username} unassigned {task.slug}</span>);
                        break;
                    case "undo":
                        setMessage(<span>{user?.username} undid the last action on {task.slug}</span>);
                        break;
                    default:
                        setMessage(<span>{user?.username} updated {task.slug}</span>);
                        break;
                }
            } else {
                setMessage(<span>{user?.username} updated {task.slug}</span>);
            }
        }
    }, [task, isError, isLoading, taskId, milestones, priorityValues, users, update, userId]);

    if (link) {
        return (
            <Link to={link}>
                <div className={highlightClass}>
                    <div className="notification__message">{message}</div>
                    <ReactTimeAgo className="ml-2 text-sm" date={date} />
                </div>
            </Link>
        );
    } else {
        return (
            <div className={highlightClass}>
                <div className="notification__message">{message}</div>
                <ReactTimeAgo className="ml-2 text-sm" date={date} />
            </div>
        );
    }
}

const ResultNotification: React.FC<{ created: string, message: string, read: boolean }> = ({ created, message, read }) => {
    const date = new Date(created + "Z");
    const highlightClass = read ? "notification__item" : "notification__item notification__item--highlight";
    return (
        <div className={highlightClass}>
            <div className="notification__message">{message}</div>
            <ReactTimeAgo className="ml-2 text-sm" date={date} />
        </div>
    );
}

const NotificationItem: React.FC<{ notification: Notification }> = ({ notification }) => {
    switch (notification.channel) {
        case "invite": {
            return <InviteNotification {...notification} message={notification.message as InviteNotification} />;
        }
        case "taskChange": {
            return <TaskChangeNotification {...notification} update={notification.message as TaskMessage} />;
        }
        default: {
            return <ResultNotification {...notification} message={notification.message as string} />;
        }
    }
};

export default function NotificationPage() {
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    React.useEffect(() => {
        fetchNotifications(1).then((notifications) => {
            setNotifications(notifications);
            markAllNotificationsRead();
        });
    }, []);

    return (
        <AppPage active="notifications">
            <div className="flex-col ml-auto mr-auto p-2">
                <h1 className="mb-4 w-full text-center">Notifications</h1>
                <div className="notification__container">
                    {notifications.map((notification) => (
                        <NotificationItem key={notification.id} notification={notification} />
                    ))}
                </div>
            </div>
        </AppPage>
    );
}
