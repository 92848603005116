import React from 'react';
import { Button } from '@/components/ui/button';
import { FaShare } from 'react-icons/fa6';
import { toast } from 'sonner';

const ShareButton: React.FC<{ className?: string }> = ({ className }) => {
    const handleShare = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url).then(
            () => toast.success('URL copied to clipboard!'),
            (err) => toast.error('Failed to copy URL:', err)
        );
    };

    return (
        <Button onClick={handleShare} className={className}>
            <FaShare />&nbsp;Share
        </Button>
    );
};

export default ShareButton;
