import store from 'store2';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { apiFetch, apiFetchNoContent, eventEmitter, useProjectsContext, useUsersContext } from '@/contexts';
import { toast } from 'sonner';
import { Toaster } from './ui/toaster';
import { Button } from './ui/button';
import { ChevronLeft } from 'lucide-react';
import Steps from './Steps';
import Spinner from './Spinner';
import { FaPlusSquare } from 'react-icons/fa';
import LedDisplay from './LED';
import { ScrollArea } from './ui/scrollarea';
import { Table, TableBody, TableCell, TableRow } from './ui/table';
import Checkbox from './ui/checkbox';
import { Label } from './ui/label';
import { Input } from './ui/input';
import { ImportStateInfo, ImportUpdate, ProjectImportState } from '@/contexts/Project';
import { UserData } from '@/contexts/User';
import { PersonelleImage } from './Personelle';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { FaMagnifyingGlass } from 'react-icons/fa6';

function loginToJira(userBoundValue: string) {
    //const clientId = 'BsvpRNVKlLGjacWWyS7JxDzsi6h5hUhw'
    const clientId = 'vq6WBc0PzInO01ltrPxVHB03DYZNUepW'
    const scopes = 'read%3Ame%20read%3Aaccount%20read%3Ajira-work%20manage%3Ajira-project%20manage%3Ajira-webhook%20read%3Ajira-user%20write%3Ajira-work%20offline_access'
    //const redirectUri = 'https%3A%2F%2Flocalhost%3A8445%2Fapp%2Flogin%2Fjira'
    const redirectUri = 'https%3A%2F%2Fsubseq.tech%2Fapp%2Flogin%2Fjira'
    const href = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${userBoundValue}&response_type=code&prompt=consent`;
    console.log("Login with", href);
    window.location.href = href;
}

async function getStateString(): Promise<{ state: string }> {
    return await apiFetch('/api/v1/user/state', { method: 'GET' });
}

async function getAccessToken(code: string, state: string) {
    console.log("Getting access token with code", code, "and state", state);
    await apiFetchNoContent(`/api/v1/jira/login?code=${code}&state=${state}`, { method: 'GET' });
}

export interface JiraProject {
    id: string;
    key: string;
    name: string;
    syncEnabled: boolean;
}

export interface JiraAccount {
    id: string;
    name: string;
    cloudId: string;
    avatarUrl: string;
}

interface JiraProjectResponse {
    projects: Record<string, JiraProject[]>;
    accounts: JiraAccount[];
}

async function getProjects(): Promise<JiraProjectResponse> {
    return await apiFetch('/api/v1/jira/projects', { method: 'GET' });
}

export function JiraImportLogin() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const code = searchParams.get("code");
        const state = searchParams.get("state");

        if (code) {
            store.set("jiraCode", { code, state });
            navigate("/app/import/jira");
        } else if (store.get("jiraCode")) {
            navigate("/app/import/jira");
        } else {
            getStateString().then((state) => {
                loginToJira(state.state);
            }).catch(() => {
                console.error("Failed to get state");
                navigate("/");
            });
        }
    }, [navigate, searchParams]);
    return null;
}

function displayInfo(info: ImportStateInfo | undefined) {
    if (typeof info === "object") {
        if ("issues" in info) {
            return `(${info.issues.issues} issues)`;
        } else if ("comments" in info) {
            return `(${info.comments.total} issues)`;
        } else if ("portraits" in info) {
            return `(${info.portraits.total} users)`;
        }
    }
    return null;
}

interface JiraImportingProps {
    steps: string[];
    importState: ImportInitialState;
    stateInfos: Map<string, ImportStateInfo>;
    setStep: (step: number) => void;
}

function JiraImporting({ steps, importState, stateInfos, setStep }: JiraImportingProps) {
    const step = 1;
    let continueActive = true;
    return (<div className="description__container p-2 mt-10 mb-10 w-[600px]">
        <Link to="../.." className='flex m-4 no-underline'>
            <Button><ChevronLeft /> Exit</Button>
        </Link>
        <h1 className="m-3">Your projects are importing from Github</h1>
        <Steps steps={steps} currentStep={step} className="mx-4" />
        <div className="dark:bg-neutral-700 rounded-lg border border-neutral-200 mt-4 mx-1">
            {importState.projectImportStates.map((state, index) => {
                const error = state.importError;
                let color: "red" | "orange" | "green";
                let blink = false;
                if (error) {
                    color = "red";
                } else if (state.importState === "done") {
                    color = "green";
                } else {
                    color = "orange";
                    blink = true;
                }

                const info = stateInfos.get(state.projectTracker.id);
                const infoDisplay = info ? displayInfo(info) : '';

                let stateString;
                if (typeof (state.importState) === 'object' && 'inProgress' in state.importState) {
                    if (infoDisplay) {
                        stateString = `Importing ${state.importState.inProgress} ${infoDisplay}`;
                    } else {
                        stateString = `Importing ${state.importState.inProgress}`;
                    }
                    continueActive = false;
                } else {
                    if (state.importState === "done") {
                        stateString = "Imported";
                    } else {
                        stateString = state.importState.toString();
                        continueActive = false;
                    }
                }

                const topDivClass = index > 0 ? "flex items-center justify-between p-4 border-t" : "flex items-center justify-between p-4";
                return <div key={state.id} className={topDivClass}>
                    <div className="flex items-center">
                        <div className="ml-8">
                            <p>{state.projectTracker.repo}</p>
                        </div>
                    </div>
                    <div className="flex">
                        {stateString}<LedDisplay status={color} blink={blink} />
                    </div>
                </div>;
            })}
        </div>
        <div className="flex items-center justify-center m-4">
            <div className="flex-grow" />
            <Button className="btn" disabled={!continueActive} onClick={() => setStep(2)}>Continue</Button>
        </div>
    </div>);

}

interface ImportInitialState {
    projectImportStates: ProjectImportState[];
}

interface JiraImportConfigurationProps {
    steps: string[];
    setImportState: (state: ImportInitialState) => void;
}

async function loginWithCode(jiraCode: { code: string, state: string }) {
    try {
        await getAccessToken(jiraCode.code, jiraCode.state);
        toast.success("Jira login successful");
        store.remove("jiraCode");
    } catch (error) {
        const typedError = error as {status: number, message: string };
        toast.error(`Jira login failed: ${typedError.status} - ${typedError.message}`);
        store.remove("jiraCode");
    }
}

function JiraImportConfiguration({ steps, setImportState }: JiraImportConfigurationProps) {
    const navigate = useNavigate();
    const [jiraProjects, setJiraProjects] = useState<Record<string, JiraProject[]>>({});
    const [jiraAccounts, setJiraAccounts] = useState<JiraAccount[]>([]);

    const [selectedProject, setSelectedProject] = useState<{ project: JiraProject, accountId: string } | null>(null);
    const [newProjectTitle, setNewProjectTitle] = useState<string>("");

    useEffect(() => {
        const jiraCode = store.get("jiraCode");
        if (jiraCode) {
            loginWithCode(jiraCode).then(() => {
                getProjects().then((projectsResponse) => {
                    const projects = projectsResponse.projects;
                    setJiraProjects(projects);
                    const accounts = projectsResponse.accounts;
                    setJiraAccounts(accounts);
                });
            });
        } else {
            getProjects().then((projectsResponse) => {
                if (projectsResponse.accounts.length > 0) {
                    const projects = projectsResponse.projects;
                    setJiraProjects(projects);
                    const accounts = projectsResponse.accounts;
                    setJiraAccounts(accounts);
                } else {
                    navigate("/app/login/jira");
                }
            }).catch(() => {
                navigate("/app/login/jira");
            });
        }
    }, [navigate]);

    const handleStartImport = () => {
        if (!selectedProject || newProjectTitle === "") {
            return;
        }

        const subseqProject = { newProject: newProjectTitle };
        const importedProject = {
            cloudId: selectedProject.accountId,
            jiraProject: selectedProject.project.key,
            subseqProject,
        };

        apiFetch<ImportInitialState>('/api/v1/jira/import', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(importedProject)
        })
            .then(setImportState)
            .catch((error) => {
                toast.error(`Failed to start import: ${error}`);
            });
    }

    const isSelected = (project: JiraProject) => !!selectedProject && selectedProject.project.id === project.id;

    return (
        <div className="description__container p-2 mt-10 mb-10 w-[600px]">
            <Link to="../.." className='flex m-4 no-underline'>
                <Button><ChevronLeft />Exit</Button>
            </Link>
            <h1>Import projects from Jira</h1>
            <Steps steps={steps} currentStep={0} className="mx-4" />
            <div className="dark:bg-neutral-700 rounded-lg border border-neutral-200 mt-4 mx-1">
                <div className="flex items-center p-4">
                    <span className="ml-2">Connected accounts</span>
                    <div className="flex-grow" />
                    <Link className="mr-10" to="/app/login/jira" target="_blank">
                        <FaPlusSquare className="mt-1 links_dot" />
                    </Link>
                </div>
                {jiraAccounts.map((account) => (
                    <div key={account.id} className="flex items-center justify-between p-4 border-t">
                        <div className="flex items-center">
                            <img src={account.avatarUrl} alt="avatar" className="w-10 h-10 rounded-full" />
                            <div className="ml-8">{account.name}</div>
                        </div>
                        <div className="flex">
                            <LedDisplay status="green" />Connected
                        </div>
                    </div>
                ))}
            </div>
            <ScrollArea className="h-48 border rounded-md mx-1">
                <Table>
                    <TableBody>
                        {Object.entries(jiraProjects).flatMap(([accountId, projects]) => (
                            projects.map((project) => (
                                <TableRow className="text-left hover:bg-neutral-300 dark:hover:bg-neutral-800" key={project.id}>
                                    <TableCell className="text-center">
                                        <Checkbox checked={isSelected(project)}
                                            onCheckedChange={(checked) =>
                                                checked ? setSelectedProject({ project, accountId }) :
                                                    setSelectedProject(null)} />
                                    </TableCell>
                                    <TableCell>{project.name}</TableCell>
                                    <TableCell>{project.syncEnabled ? "Synced" : ""}</TableCell>
                                </TableRow>
                            ))
                        ))}
                    </TableBody>
                </Table>
            </ScrollArea>
            <div className="p-4 border rounded-md mt-4 mx-1">
                <h4 className="mb-2">Name project for import</h4>
                <div className="flex justify-center">
                    <Label className="m-4">Project name</Label>
                    <Input className="w-[180px]" value={newProjectTitle} onChange={(e) => setNewProjectTitle(e.currentTarget.value)} />
                </div>
            </div>

            <div className="flex mt-4">
                <div className="flex-grow" />
                <Button className="m-4 btn" disabled={!selectedProject || newProjectTitle === ""} onClick={handleStartImport}>Start Import</Button>
            </div>
        </div>
    );
}

async function getActiveImport(): Promise<ImportInitialState | null> {
    try {
        return await apiFetch<ImportInitialState>('/api/v1/jira/import', { method: 'GET' });
    } catch {
        return null;
    }
}

async function linkJiraUsers(request: { userToLink: string, importedUsers: string[] }) {
    const response = await fetch('/api/v1/jira/import/users', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
    });
    if (!response.ok) {
        const error = await response.text();
        throw error;
    }
}

async function getImportedUsers(): Promise<UserData[]> {
    return await apiFetch('/api/v1/jira/import/users', { method: 'GET' });
}

function JiraAccountLinking({ steps }: { steps: string[] }) {
    const step = 2;
    const { users } = useUsersContext();
    const [importedUsers, setImportedUsers] = useState<UserData[]>([]);

    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [selectedImportedUsers, setImportedSelected] = useState<string[]>([]);

    const [alreadyLinkedUsers, setAlreadyLinkedUsers] = useState<string[]>([]);
    const [alreadyLinkedImportedUsers, setAlreadyLinkedImportedUsers] = useState<string[]>([]);
    const activeUsers = users.filter((user) => !alreadyLinkedUsers.includes(user.id));

    const linkUsers = useCallback(() => {
        if (selectedImportedUsers.length === 0 || selectedUser === null) {
            return;
        }
        linkJiraUsers({ userToLink: selectedUser, importedUsers: selectedImportedUsers })
            .then(() => {
                toast.success('Users linked successfully');
                setSelectedUser(null);
                setImportedSelected([]);
                setAlreadyLinkedImportedUsers((linked) => [...linked, ...selectedImportedUsers]);
                setAlreadyLinkedUsers((linked) => [...linked, selectedUser]);
            })
            .catch((error) => {
                eventEmitter.emit('ERROR', error);
            });
    }, [selectedImportedUsers, selectedUser]);

    const setSelected = useCallback((userId: string) => {
        setImportedSelected((selected) => {
            if (selected.includes(userId)) {
                return selected.filter((id) => id !== userId);
            } else {
                return [...selected, userId];
            }
        });
    }, []);

    useEffect(() => {
        getImportedUsers().then(setImportedUsers);
    }, []);

    const [filter, setFilter] = useState("");
    const filterList = useCallback((users: UserData[]) => {
        const filteredUsers = users.filter((user) => !alreadyLinkedImportedUsers.includes(user.id));

        if (filter === "") {
            return filteredUsers;
        }

        return users.filter((user) => user.username.toLowerCase().includes(filter.toLowerCase()));
    }, [filter, alreadyLinkedImportedUsers]);
    const filteredUsers = filterList(importedUsers);

    const listItems = filteredUsers.map((userData) => (
        <li key={userData.id} onMouseDown={() => setSelected(userData.id)} className="flex items-center">
            <PersonelleImage userData={userData} className="p-1 rounded-full" />
            <span className="personelle__text">{userData.username}</span>
            <Checkbox checked={selectedImportedUsers.indexOf(userData.id) > -1} className="ml-2 mr-1" />
        </li>
    ));

    return (<div className="description__container p-2 mt-10 mb-10 w-[600px]">
        <Link to="../.." className='flex m-4 no-underline'>
            <Button><ChevronLeft /> Exit</Button>
        </Link>
        <h1 className="m-3">Connect Github accounts for your team</h1>
        <Steps steps={steps} currentStep={step} className="mx-4" />
        <div className="grid grid-cols-2 p-2 border rounded-lg mx-1">
            <div className="p-2">
                <Select onValueChange={setSelectedUser}>
                    <SelectTrigger className="m-2">
                        <SelectValue placeholder="Select a team member to link"></SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {activeUsers.map((user) => (
                                <SelectItem value={user.id}>
                                    <div className="flex items-center">
                                        <PersonelleImage userData={user} className="p-1 rounded-full" />
                                        <div className="ml-4 personelle__text">
                                            {user.username}
                                        </div>
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            <div className="p-2">
                <FaMagnifyingGlass className="absolute translate-y-5 translate-x-64" />
                <Input className="m-2" onChange={(e) => setFilter(e.target.value)} value={filter} />
                <ul>
                    {listItems}
                </ul>
            </div>
            <Button disabled={selectedUser === null || selectedImportedUsers.length === 0} className="btn col-span-2 m-auto mt-4 mb-4 w-1/3" onClick={linkUsers}>Link Accounts</Button>
        </div>
        <div className="flex m-4">
            <div className="flex-grow" />
            <Link to="/app/projects"><Button className="btn">Done</Button></Link>
        </div>
    </div>);
}

function JiraImportPage() {
    const steps = ["Configure", "Import", "Link"];
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [activeImport, setActiveImport] = useState<ImportInitialState | null>(null);
    const [stateInfos, setStateInfos] = useState(new Map<string, ImportStateInfo>());
    const { clearProjectCache } = useProjectsContext();

    useEffect(() => {
        getActiveImport().then(setActiveImport);
    }, []);

    let step = parseInt(searchParams.get('step') || '0') || 0;
    if (step < 0 || step >= steps.length) {
        step = 0;
    }

    const setStep = useCallback((step: number) => {
        navigate(`/app/import/jira?step=${step}`);
    }, [navigate]);

    const setImportState = useCallback((state: ImportInitialState) => {
        setActiveImport(state);
        setStep(step + 1);
        clearProjectCache();
    }, [step, setStep, clearProjectCache]);

    useEffect(() => {
        if (activeImport) {
            const handleImportUpdates = ({ update: stateUpdate }: { update: ImportUpdate }) => {
                console.log('IMPORT-UPDATE', stateUpdate);
                setStateInfos((infos) => {
                    const newInfos = new Map(infos);
                    newInfos.set(stateUpdate.projectTrackerId, stateUpdate.info);
                    return newInfos;
                });
                setActiveImport((state) => {
                    if (state) {
                        return {
                            ...state, projectImportStates: state.projectImportStates.map((proj) => {
                                if (proj.projectTracker.id === stateUpdate.projectTrackerId) {
                                    return {
                                        ...proj,
                                        importState: stateUpdate.state,
                                        importError: stateUpdate.error,
                                    };
                                } else {
                                    return proj;
                                }
                            })
                        };
                    } else {
                        return null;
                    }
                });
            };
            eventEmitter.on('IMPORT-UPDATE', handleImportUpdates);
            return () => {
                eventEmitter.off('IMPORT-UPDATE', handleImportUpdates);
            }
        }
    }, [activeImport, setStep]);

    let displayNode = null;
    if (step === 0) {
        displayNode = <JiraImportConfiguration steps={steps} setImportState={setImportState} />;
    } else if (step === 1 && activeImport) {
        displayNode = <JiraImporting steps={steps} importState={activeImport} stateInfos={stateInfos} setStep={setStep} />;
    } else if (step === 2 && activeImport) {
        displayNode = <JiraAccountLinking steps={steps} />;
    } else {
        displayNode = <Spinner />;
    }

    return (
        <>
            {displayNode}
            <Toaster />
        </>
    );
}

export default JiraImportPage;
