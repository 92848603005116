import { ChevronLeft } from 'lucide-react';
import { FaAtlassian, FaGithub } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

import { Button } from '@/components/ui/button';

function ImportPage() {
    return (
        <div className="description__container p-2 mt-10 mb-10 w-[600px]">
            <Link to="/app/projects" className='flex m-4 no-underline'>
                <Button><ChevronLeft /> Exit</Button>
            </Link>
            <Link to="/app/import/github" className="grid grid-cols-2 btn my-4 p-4 items-center no-underline">
                <FaGithub className="m-2" />
                <p>Import your issues from Github</p>
            </Link>
            <Link to="/app/import/jira" className="grid grid-cols-2 btn my-4 p-4 items-center no-underline">
                <FaAtlassian className="m-2" />
                <p>Import your issues from Jira</p>
            </Link>
        </div>
    );
}
export default ImportPage;
