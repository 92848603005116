import React, { useCallback, useEffect, useState } from 'react';
import { addDays } from "date-fns";
import { FaXmark } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { MilestoneIcon } from 'lucide-react';
import { useForm } from "react-hook-form";
import { z } from 'zod';

import DetailGrid, { DetailContentArea, } from "@/components/cards/DetailGrid";
import { useMilestoneContext } from '@/contexts';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select';
import { DatePickerWithRange } from '@/components/ui/datepickerwithrange';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { MilestoneType, RepeatData, Timeline } from '@/contexts/Milestone';
import { DatePicker } from '@/components/ui/datepicker';
import Checkbox from '@/components/ui/checkbox';

const NewMilestoneSchema = z.object({
    name: z.string(),
    description: z.string(),
    milestoneType: z.union([
        z.string(),
        z.object({ timeline: z.string() }),
        z.object({ deadline: z.string() }),
    ]).optional(),
    dateRange: z.object({
        startDate: z.date().optional(),
        dueDate: z.date().optional(),
    }).optional(),
    repeat: z.object({
        repeats: z.string(),
        repeatEnd: z.date().optional(),
        repeatSchema: z.union([
            z.object({ increment: z.number() }),
            z.object({ date: z.string() }),
        ]),
    }).optional(),
});

function getLastDayOfWeek(date: Date = new Date()): Date {
    const dayOfWeek = date.getDay(); // 0 (Sunday) - 6 (Saturday)
    const diff = 6 - dayOfWeek;
    const lastDayOfWeek = new Date(date);
    lastDayOfWeek.setDate(date.getDate() + diff);
    return lastDayOfWeek;
}

function getLastDayOfMonth(date: Date = new Date()): Date {
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDayOfMonth;
}

function getLastDayOfYear(date: Date = new Date()): Date {
    const lastDayOfYear = new Date(date.getFullYear(), 11, 31);
    return lastDayOfYear;
}

function getLastDayOfQuarter(date: Date = new Date()): Date {
    const month = date.getMonth();
    const year = date.getFullYear();

    let lastMonthOfQuarter: number;
    if (month >= 0 && month <= 2) {
        lastMonthOfQuarter = 2;  // Q1: January (0) - March (2)
    } else if (month >= 3 && month <= 5) {
        lastMonthOfQuarter = 5;  // Q2: April (3) - June (5)
    } else if (month >= 6 && month <= 8) {
        lastMonthOfQuarter = 8;  // Q3: July (6) - September (8)
    } else {
        lastMonthOfQuarter = 11; // Q4: October (9) - December (11)
    }

    const lastDayOfQuarter = new Date(year, lastMonthOfQuarter + 1, 0);
    return lastDayOfQuarter;
}

function RepeatForm({ name, defaultRepeat, value, onChange }: { name?: string, defaultRepeat: string, value?: RepeatData, onChange: (value?: RepeatData) => void }) {
    let schemaValueState: string | null = null;
    let schemaState: "increment" | "date" | null = null;

    if (value) {
        if ("increment" in value.repeatSchema) {
            schemaValueState = value.repeatSchema.increment.toString();
            schemaState = "increment";
        } else {
            schemaValueState = value.repeatSchema.date;
            schemaState = "date";
        }
    }
    const [schema, setSchema_] = React.useState(schemaState);
    const [schemaValue, setSchemaValue_] = React.useState(schemaValueState);

    const handleRepeatsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const repeats = event.target.value;
        if (value) {
            onChange({ ...value, repeats });
        }
    };

    const setSchema = (newSchema: "increment" | "date") => {
        setSchema_(newSchema);
        if (value && schemaValue) {
            if (newSchema === "increment") {
                const newSchemaValue = "1";
                setSchemaValue(newSchemaValue);
                onChange({ ...value, repeatSchema: { increment: parseInt(newSchemaValue) } });
            } else {
                const newSchemaValue = "%M/%D/%Y";
                setSchemaValue(newSchemaValue);
                onChange({ ...value, repeatSchema: { date: newSchemaValue } });
            }
        }
    }

    const setSchemaValue = (schemaValue: string) => {
        setSchemaValue_(schemaValue);
        if (value && schema) {
            if (schema === "increment") {
                onChange({ ...value, repeatSchema: { increment: parseInt(schemaValue) } });
            } else {
                onChange({ ...value, repeatSchema: { date: schemaValue } });
            }
        }
    }

    const handleRepeatEndChange = (date: Date | undefined) => {
        if (value) {
            onChange({ ...value, repeatEnd: date });
        }
    };

    return (
        <>
            <FormItem>
                <FormLabel>Repeats</FormLabel>
                <FormControl>
                    <Checkbox checked={!!value} onCheckedChange={(checked) => {
                        if (checked) {
                            setSchema_("increment");
                            setSchemaValue_("1");
                            onChange({ repeats: defaultRepeat, repeatSchema: { increment: 1 } });
                        } else {
                            onChange(undefined);
                        }
                    }} />
                </FormControl>
                <FormDescription>Should this milestone repeat?</FormDescription>
            </FormItem>
            {value && <>
                <FormItem>
                    <FormLabel>Repeats every</FormLabel>
                    <FormControl>
                        <Input className="w-1/2" type="text" value={value.repeats} onChange={handleRepeatsChange} />
                    </FormControl>
                    <FormDescription>Enter a time period (e.g. 3 days, 2 weeks)</FormDescription>
                </FormItem>
                <FormItem className="justify-center">
                    <FormLabel className="col-span-2">Repeat naming</FormLabel>
                    <Select value={schema as string | undefined} onValueChange={(s) => setSchema(s as "increment" | "date")}>
                        <FormControl className="w-2/3 ml-auto mr-2">
                            <SelectTrigger>
                                <SelectValue placeholder="Naming scheme"></SelectValue>
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent className="panel">
                            <SelectItem className='select__hover' value='increment'>Incrementing</SelectItem>
                            <SelectItem className='select__hover' value='date'>Date</SelectItem>
                        </SelectContent>
                    </Select>
                    {schema === "date" ? (
                        <Input className="w-1/2" type="string" value={schemaValue || undefined} onChange={(e) => setSchemaValue(e.target.value)} />
                    ) : (
                        <Input
                            className="w-1/2"
                            type="number"
                            value={schemaValue || undefined}
                            onChange={(e) => setSchemaValue(e.target.value)} />
                    )}
                    <FormDescription>Enter the naming schema. Dates use strftime notation.<br/><span className="text-lg">Name will appear as:&nbsp;{`${name} ${schema === "increment" ? schemaValue : (new Date()).toLocaleDateString()}`}</span></FormDescription>
                </FormItem>
                <FormItem>
                    <FormLabel>Repeat until</FormLabel>
                    <DatePicker selected={value.repeatEnd} onSelect={handleRepeatEndChange} />
                    <FormDescription>(optional) Set the end date for the repeat</FormDescription>
                </FormItem>
            </>}
        </>
    );
}

function DataCreate() {
    const { createMilestone } = useMilestoneContext();
    const navigate = useNavigate();
    const modalRef = React.createRef<HTMLDivElement>();

    const vanish = () => {
        navigate(-1);
    };

    const [today] = React.useState(new Date(Date.now()));
    const [toDate, setToDate_] = React.useState(today);

    const form = useForm<z.infer<typeof NewMilestoneSchema>>({
        resolver: zodResolver(NewMilestoneSchema),
        defaultValues: {
            name: "",
            description: "",
            milestoneType: undefined,
            dateRange: { startDate: today, dueDate: toDate },
            repeat: undefined,
        }
    });

    const setToDate = useCallback((date: Date) => {
        setToDate_(date);
        form.setValue("dateRange.dueDate", date);
    }, [form]);

    const [defaultRepeat, setDefaultRepeat_] = useState("2 weeks");

    const setDefaultRepeat = useCallback((milestoneType: MilestoneType) => {
        if (typeof(milestoneType) === "object" && "timeline" in milestoneType) {
            switch (milestoneType["timeline"]) {
                case "week": {
                    setDefaultRepeat_("1 week");
                    break;
                }
                case "sprint": {
                    setDefaultRepeat_("2 weeks");
                    break;
                }
                case "month": {
                    setDefaultRepeat_("1 month");
                    break;
                }
                case "quarter": {
                    setDefaultRepeat_("4 months");
                    break;
                }
                case "year": {
                    setDefaultRepeat_("1 year");
                    break;
                }
                default: setDefaultRepeat_("2 weeks");
            }
            return;
        }
        setDefaultRepeat_("2 weeks");
    }, []);

    useEffect(() => {
        const selectedType = form.getValues("milestoneType");
        if (selectedType) {
            setDefaultRepeat(selectedType as MilestoneType);
        }
        const timeline: Timeline | null = selectedType && typeof (selectedType) === "object" && "timeline" in selectedType ? selectedType.timeline as Timeline : null;
        if (timeline === "week") {
            setToDate(getLastDayOfWeek(today));
        } else if (timeline === "sprint") {
            setToDate(addDays(getLastDayOfWeek(today), 7));
        } else if (timeline === "month") {
            setToDate(getLastDayOfMonth(today));
        } else if (timeline === "quarter") {
            setToDate(getLastDayOfQuarter(today));
        } else if (timeline === "year") {
            setToDate(getLastDayOfYear(today));
        }
    }, [today, form, setToDate, setDefaultRepeat]);

    const handleSubmit = (data: z.infer<typeof NewMilestoneSchema>) => {
        const { name, description, milestoneType, dateRange, repeat } = data;
        const milestoneTypeValue: MilestoneType | null = milestoneType as MilestoneType ?? null;
        const startDate = dateRange && dateRange.startDate ? dateRange.startDate.toISOString() : undefined;
        let dueDate = dateRange && dateRange.dueDate ? dateRange.dueDate.toISOString() : undefined;
        if (startDate === dueDate) {
            dueDate = undefined;
        }
        createMilestone({ name, description, milestoneType: milestoneTypeValue, dueDate, startDate, repeat });
        vanish();
    };

    console.log("Rendering MilestoneCreate");
    return (
        <div ref={modalRef} className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <MilestoneIcon size={24} />
                </div>
                <div className="flex-grow" />
                <div className="card-detail__header-container">
                    <h2 className="card-detail__header">Create Milestone</h2>
                </div>
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <Form {...form}>
                        <form className="flex flex-col" onSubmit={form.handleSubmit(handleSubmit)}>
                            <FormField control={form.control} name="name" render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl className="w-2/3">
                                        <Input type="text" value={field.value} onChange={field.onChange} />
                                    </FormControl>
                                    <FormDescription>Enter the name of the milestone</FormDescription>
                                </FormItem>
                            )} />
                            <FormField control={form.control} name="description" render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="col-span-2">Description</FormLabel>
                                    <FormControl>
                                        <Textarea className="col-span-2 m-auto mt-4 w-2/3 min-h-16" value={field.value} onChange={field.onChange} />
                                    </FormControl>
                                    <FormDescription>Enter a description of the milestone</FormDescription>
                                </FormItem>
                            )} />
                            <FormField control={form.control} name="milestoneType" render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Milestone Type</FormLabel>
                                    <Select onValueChange={(value) => {
                                        const parsedValue = JSON.parse(value);
                                        setDefaultRepeat(parsedValue);
                                        field.onChange(parsedValue);
                                    }} defaultValue={JSON.stringify(field.value)}>
                                        <FormControl>
                                            <SelectTrigger className="m-auto mt-4 w-1/2">
                                                <SelectValue placeholder="Select a type..." className='bg-white'></SelectValue>
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent className="panel">
                                            <SelectGroup>
                                                <SelectLabel className="font-bold dark:bg-neutral-800">Timeline</SelectLabel>
                                                <SelectItem className='select__hover' value='{"timeline": "week"}'>End of Week</SelectItem>
                                                <SelectItem className='select__hover' value='{"timeline": "sprint"}'>Sprint</SelectItem>
                                                <SelectItem className='select__hover' value='{"timeline": "month"}'>End of Month</SelectItem>
                                                <SelectItem className='select__hover' value='{"timeline": "quarter"}'>End of Quarter</SelectItem>
                                                <SelectItem className='select__hover' value='{"timeline": "year"}'>End of Year</SelectItem>
                                            </SelectGroup>
                                            <SelectGroup>
                                                <SelectLabel className="font-bold dark:bg-neutral-800">General</SelectLabel>
                                                <SelectItem className='select__hover' value='"checkpoint"'>Checkpoint</SelectItem>
                                                <SelectItem className='select__hover' value='"epic"'>Epic</SelectItem>
                                                <SelectItem className='select__hover' value='"launch"'>Launch</SelectItem>
                                                <SelectItem className='select__hover' value='"review"'>Review</SelectItem>
                                                <SelectItem className='select__hover' value='"version"'>Version</SelectItem>
                                            </SelectGroup>
                                            <SelectGroup>
                                                <SelectLabel className='font-bold dark:bg-neutral-800'>Deadline</SelectLabel>
                                                <SelectItem className='select__hover' value='{"deadline": "commitment"}'>Commitment</SelectItem>
                                                <SelectItem className='select__hover' value='{"deadline": "contract"}'>Contract</SelectItem>
                                                <SelectItem className='select__hover' value='{"deadline": "deliverable"}'>Deliverable</SelectItem>
                                                <SelectItem className='select__hover' value='{"deadline": "event"}'>Event</SelectItem>
                                                <SelectItem className='select__hover' value='{"deadline": "presentation"}'>Presentation</SelectItem> <SelectItem className='dark:bg-neutral-900 hover:bg-neutral-600' value='{"deadline": "production"}'>Manufacturing</SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <FormDescription>Select the type of milestone</FormDescription>
                                </FormItem>
                            )} />
                            <FormField control={form.control} name="dateRange" render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Date Range</FormLabel>
                                    <FormControl>
                                        <DatePickerWithRange
                                            className="items-center m-auto w-1/2 mt-4"
                                            fromDate={field.value?.startDate}
                                            toDate={field.value?.dueDate}
                                            onRangeChange={(range) => {
                                                field.onChange({ startDate: range.from, dueDate: range.to });
                                            }} />
                                    </FormControl>
                                    <FormDescription>Enter the period the milestone will be active for</FormDescription>
                                </FormItem>
                            )} />
                            <FormField control={form.control} name="repeat" render={({ field }) => (
                                <RepeatForm
                                    name={form.getValues("name")}
                                    defaultRepeat={defaultRepeat}
                                    value={field.value}
                                    onChange={field.onChange} />
                            )} />
                            <Button type="submit" className="m-auto mt-4 w-1/2 max-w-72 btn">Create Milestone</Button>
                        </form>
                    </Form>
                </DetailContentArea>
            </DetailGrid>
        </div>);
}
export default DataCreate;
