import React, { useRef } from 'react';
import { toast } from 'sonner';
import { Button } from './ui/button';
import { FaFileUpload } from 'react-icons/fa';

const FileUpload: React.FC<{ uploadPath: string, className: string }> = ({ uploadPath, className }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const openFilePicker = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    // Handler for file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files ? event.target.files[0] : null;

        if (!selectedFile) {
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        fetch(uploadPath, {
            method: 'POST',
            body: formData,
        }).then((response) => {
            if (response.ok) {
                toast.success('File uploaded successfully');
            } else {
                toast.error('Failed to upload file');
            }
            // Clear file input
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }).catch(() => {
            toast.error('Failed to upload file');
            // Clear file input
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        });
    };

    return (
        <div>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            <Button className={className} onClick={openFilePicker}><FaFileUpload />&nbsp;Upload</Button>
        </div>
    );
};

export default FileUpload;
