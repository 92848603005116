import { memo, useEffect, useRef } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { Project } from "../../../contexts/Project";
import { CardData } from "../CardDataInterfaces";
import { FaProjectDiagram } from "react-icons/fa";
import { ListChildComponentProps, areEqual } from "react-window";

export const Card = memo(({ data, index, style }: ListChildComponentProps) => {
    const { items, dragging, handleClick, setDragging, dropRule } = data;
    const project = items[index];
    if (!project) {
        return null;
    }

    const height = style.height as number;
    const isActiveDrag = !!dragging && dragging.id === project.id;
    const opacity = isActiveDrag ? 0 : 1;

    const dynamicStyle = {
        ...style,
        opacity,
        margin: 8,
        marginLeft: 16,
        height: height - 16,
        width: 'calc(100% - 32px)',
    };

    return (<MiniProject
        key={`project-${project.id}`}
        handleClick={() => handleClick(project.id)}
        card={project}
        isActiveDrag={isActiveDrag}
        setDragging={(card: CardData | null) => setDragging(card as Project | null)}
        style={dynamicStyle}
        dropRule={dropRule}
    />);
}, areEqual);

interface MiniProjectProps {
    handleClick: () => void;
    card: Project;
    isActiveDrag: boolean;
    setDragging: (dragging: CardData | null) => void;
    style: React.CSSProperties
    dropRule: (card: Project) => (item: unknown, monitor: DropTargetMonitor) => void;
}

function MiniProject({ handleClick, card, isActiveDrag, setDragging, style, dropRule }: MiniProjectProps) {
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging, handlerId }, connectDrag] = useDrag({
        type: "GRID_ITEM",
        item: card,
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            };
        },
    });

    useEffect(() => {
        if (!isActiveDrag && isDragging) {
            setDragging(card);
        } else if (isActiveDrag && !isDragging) {
            setDragging(null);
        }
    }, [isActiveDrag, card, setDragging, isDragging]);

    const [, connectDrop] = useDrop({
        accept: "GRID_ITEM",
        drop: dropRule(card)
    });
    connectDrag(ref);
    connectDrop(ref);

    return (
        <div
            ref={ref}
            onClick={handleClick}
            className="card"
            style={style}
            data-handler-id={handlerId}
        >
            <div className="card__top-bar">
                <div className="card__icon">
                    <FaProjectDiagram />
                </div>
                <div className="card-header">{card.slug}</div>
            </div>
            <div>{card.name}</div>
        </div>
    );
}
export default MiniProject;
