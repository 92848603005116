import { FaXmark } from "react-icons/fa6";
import { FaTasks } from "react-icons/fa";
import React, { useCallback, useState } from "react";
import Personelle, { PersonelleSelector } from "../../Personelle";
import { TaskCreateData, useAddTask } from "../../../contexts/Task";
import { UserData } from "../../../contexts/User";
import { useAppContext, useProjectsContext, useUsersContext } from "../../../contexts";
import DetailGrid, {
    DetailContentArea,
    DetailSidePanel,
} from "../DetailGrid";
import { useNavigate } from "react-router-dom";
import Priority from "./Priority";
import Editor from "@/components/Editor";
import { EditDateTime } from "../EditableDropdown";
import Floater from "../../Floater";

function TaskCreate() {
    const navigate = useNavigate();
    const { loggedInUser, narrow } = useAppContext();
    const { activeProject } = useProjectsContext();
    const { users } = useUsersContext();
    const [author] = useState<UserData | null>(loggedInUser || null);
    const { mutate: addTask } = useAddTask();

    const [taskData, setTaskData] = useState<TaskCreateData>({
        projectId: activeProject?.id || "",
        description: "",
        priority: 0
    });
    const modalRef = React.createRef<HTMLDivElement>();

    const vanish = () => {
        navigate(-1);
    };

    function assignUser(userId: string) {
        setTaskData({
            ...taskData,
            assigneeId: userId,
        });
    }

    const onSetPriority = useCallback((value: number) => {
        setTaskData({
            ...taskData,
            priority: value
        });
    }, [taskData]);

    const onSetDate = useCallback((dateString: string | null) => {
        setTaskData({
            ...taskData,
            dueDate: dateString || undefined
        });
    }, [taskData]);

    function saveTask(taskData: TaskCreateData) {
        addTask(taskData);
        vanish();
    }

    const DetailContentMode = !narrow ? (
        <DetailContentArea>
            <Editor
                markdown={taskData.description}
                placeholder="Enter a task description..."
                users={users}
                onSaveMarkdown={(markdown) => {
                    const createdTaskData = { ...taskData, description: markdown };
                    setTaskData(createdTaskData)
                    saveTask(createdTaskData);
                }}
            />
        </DetailContentArea>
    ) : (
        <DetailContentArea>
            <Editor
                markdown={taskData.description}
                placeholder="Enter a task description..."
                users={users}
                onSaveMarkdown={(markdown) => {
                    const createdTaskData = { ...taskData, description: markdown };
                    setTaskData(createdTaskData)
                    saveTask(createdTaskData);
                }}
            />
            <div className="m-2 items-center rounded-lg border p-4">
                <Floater content="Author of this task">
                    {author && <Personelle userData={author} tag="author" />}
                </Floater>
                <div className="h-2" />
                <Floater content="Assignee for this task">
                    <PersonelleSelector
                        selectedUserData={users.find((user) => user.id == taskData.assigneeId)}
                        tag="assignee"
                        userSelectionShortList={users}
                        handleUserSelect={assignUser}
                    />
                </Floater>
            </div>
            <Priority value={taskData.priority} onSetPriority={onSetPriority} />
            <EditDateTime
                textLabel="Due Date"
                date={taskData.dueDate}
                onCompleted={onSetDate}
            />
        </DetailContentArea>
    );

    const DetailSidePanelMode = !narrow ? (
        <DetailSidePanel>
            <div className="m-2 items-center rounded-lg border p-4">
                <Floater content="Author of this task">
                    {author && <Personelle userData={author} tag="author" />}
                </Floater>
                <div className="h-2" />
                <Floater content="Assignee for this task">
                    <PersonelleSelector
                        selectedUserData={users.find((user) => user.id == taskData.assigneeId)}
                        tag="assignee"
                        userSelectionShortList={users}
                        handleUserSelect={assignUser}
                    />
                </Floater>
            </div>
            <Priority value={taskData.priority} onSetPriority={onSetPriority} />
            <EditDateTime
                textLabel="Due Date"
                date={taskData.dueDate}
                onCompleted={onSetDate}
            />
        </DetailSidePanel>
    ) : <div />;

    return (
        <div ref={modalRef} className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <FaTasks />
                </div>
                <div className="flex-grow" />
                <div className="card-detail__header-container">
                    <h2 className="card-detail__header">Create a Task</h2>
                </div>
                <div className="flex-grow" />
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <DetailGrid narrow={narrow}>
                {DetailSidePanelMode}
                {DetailContentMode}
            </DetailGrid>
        </div>
    );
}

export default TaskCreate;
