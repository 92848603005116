import { useCallback, useEffect, useState } from 'react';
import { Card } from './MiniData';
import InfiniteLoader from 'react-window-infinite-loader';
import AppPage from '../../AppPage';
import { useDataContext } from '../../../contexts';
import UtilityCard from "../UtilityCard.tsx";
import { FixedSizeList } from 'react-window';
import { FaPlus } from 'react-icons/fa';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DataDetailsView from './DataDetailsView.tsx';

const BOTTOM_MARGIN = 293;

const DataPage: React.FC = () => {
    const navigate = useNavigate();
    const { transcripts, hasNextPage, loadNextPage } = useDataContext();

    const handleUtilClick = useCallback(() => {
        navigate("/app/data/create");
    }, [navigate]);

    const [utilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const [utilText] = useState("Upload Transcripts");

    const isItemLoaded = useCallback((index: number): boolean => {
        return index < transcripts.length;
    }, [transcripts.length]);

    const defaultHeight = useCallback(() => {
        let margin = 0;
        margin += BOTTOM_MARGIN;
        return window.innerHeight - margin;
    }, []);

    const defaultWidth = useCallback(() => {
        if (window.innerWidth < 1300) {
            return 225;
        }
        return 450;
    }, []);

    const [height, setHeight] = useState(defaultHeight());
    const [width, setWidth] = useState(defaultWidth());

    useEffect(() => {
        const resizeListener = () => {
            setHeight(defaultHeight());
            setWidth(defaultWidth());
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, [defaultHeight, defaultWidth]);

    const itemData = {
        items: transcripts,
        handleClick: (id: string) => navigate(`/app/data/${id}`),
    };

    return <AppPage active="data">
        <div className="flex-col">
            <UtilityCard
                handleCardClick={handleUtilClick}
                icon={utilIcon}
                style={{ height: "64px", width: "calc(100% - 32px)" }}
                cardText={utilText}
            />
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={hasNextPage ? transcripts.length + 1 : transcripts.length}
                loadMoreItems={loadNextPage}
            >
                {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                        height={height}
                        width={width}
                        itemSize={160}
                        itemData={itemData}
                        itemCount={transcripts.length}
                        onItemsRendered={onItemsRendered}
                        ref={ref}>
                        {Card}
                    </FixedSizeList>
                )}
            </InfiniteLoader>
        </div>
        <Routes>
            <Route path=":id" element={<DataDetailsView />} />
        </Routes>
    </AppPage>;
};
export default DataPage;
