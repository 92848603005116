import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { BeautifulMentionNode, BeautifulMentionsMenuItemProps, BeautifulMentionsMenuProps, BeautifulMentionsPlugin, BeautifulMentionsTheme } from "lexical-beautiful-mentions";
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { $convertFromMarkdownString } from "@lexical/markdown";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";

import ToolbarPlugin from "@/plugins/ToolbarPlugin";
import { MarkdownSave, TRANSFORMERS_PLUS } from "@/plugins/MarkdownSave";
import { UserData } from "@/contexts/User";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { getTextWidth } from "@/tools";

const beautifulMentionsTheme: BeautifulMentionsTheme = {
    "@": "bg-cerulean-600 dark:bg-aquamarine-400 text-white dark:text-black rounded-md px-1 mx-px",
    "@Focused": "shadow-md shadow-cerulean-600 dark:shadow-aquamarine-400 bg-cerulean-600 dark:bg-aquamarine-400 text-white dark:text-black rounded-md px-1 mx-px",
}

const initialConfig = {
    namespace: 'lexical-editor',
    theme: {
        root: 'p-4 border-slate-500 dark:bg-neutral-800 border rounded h-full min-h-[200px] focus:outline-none focus-visible:border-black',
        link: 'cursor-pointer',
        text: {
            bold: 'font-semibold',
            underline: 'underline',
            italic: 'italic',
            strikethrough: 'line-through',
            underlineStrikethrough: 'underlined-line-through',
        },
        beautifulMentions: beautifulMentionsTheme,
    },
    onError(error: Error) {
        console.error(error);
    },
    nodes: [
        BeautifulMentionNode,
        HeadingNode,
        QuoteNode,
        TableCellNode,
        TableNode,
        TableRowNode,
        ListItemNode,
        ListNode,
        CodeHighlightNode,
        CodeNode,
        AutoLinkNode,
        LinkNode,
    ]
};

const MenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(({ selected, ...props }, ref) => (
    <li className={`m-0 w-full flex ${selected ? "bg-netural-400 dark:bg-neutral-700" : ""}`} ref={ref} {...props} />
));

function LexicalEditor({ markdown, placeholder, users, onSaveMarkdown }: EditorProps) {
    const mentionItems = {
        "@": users.map(user => ({
            id: user.id,
            value: user.username,
        })),
    };

    const config = {
        ...initialConfig,
        editorState: () => $convertFromMarkdownString(markdown, TRANSFORMERS_PLUS),
    };

    const [width, setWidth] = useState(16);
    useEffect(() => {
        let widthCalc = 16;
        for (const user of users) {
            const username = user.username;
            widthCalc = Math.max(widthCalc, getTextWidth(username, '16px ui-sans-serif'));
        }
        setWidth(Math.ceil(widthCalc));
    }, [users]);

    const Menu = useCallback(({ loading, ...props }: BeautifulMentionsMenuProps) => {
        const className = `m-0 p-0 bg-neutral-200 dark:bg-neutral-900 w-[${width}px] rounded-md normal-case`;
        if (loading) {
            return <div className={className}>Loading...</div>
        }
        return <div className={className}><ul {...props} /></div>;
    }, [width]);

    return (
        <LexicalComposer initialConfig={config}>
            <ToolbarPlugin>
                <MarkdownSave onSaveMarkdown={onSaveMarkdown} />
            </ToolbarPlugin>
            <RichTextPlugin
                contentEditable={<ContentEditable className="content-editable" />}
                placeholder={<div className="placeholder">{placeholder}</div>}
                ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <LinkPlugin />
            <ListPlugin />
            <BeautifulMentionsPlugin items={mentionItems} menuComponent={Menu} menuItemComponent={MenuItem} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS_PLUS} />
        </LexicalComposer>
    );
}

interface EditorProps {
    markdown: string;
    placeholder?: string;
    users: UserData[];
    onSaveMarkdown(markdown: string): void;
}

const Editor = (props: EditorProps) => {
    return (
        <div id="editor-wrapper"
            className={
                "relative text-left prose prose-slate prose-p:my-0 prose-headings:mb-4 prose-headings:mt-2"
            }
        >
            <LexicalEditor {...props} />
        </div>
    );
}

export default Editor;
