import { useCallback, useEffect, useState } from "react";
import { Card } from "./MiniTask";
import { isTaskData, CardData } from "../CardDataInterfaces.tsx";
import { useAppContext, useProjectsContext } from "../../../contexts";
import { FilterRule, TaskData, useTasks, useUpdateTask } from "../../../contexts/Task";
import { FaArchive, FaPlus, FaRegWindowClose } from "react-icons/fa";
import UtilityCard from "../UtilityCard.tsx";
import { useNavigate } from "react-router-dom";
import { DropTargetMonitor } from "react-dnd";

import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import { Skeleton } from "@/components/ui/skeleton.tsx";

const BOTTOM_MARGIN = 40;
const UTILITY_MARGIN = 104;

interface TaskGridProps {
    containerWidth: number;
    containerHeight: number;
    dragging: TaskData | null;
    setDragging: (task: TaskData | null) => void;
    filterRule?: FilterRule;
    showUtilityCard: boolean;
    dropRule: (card: TaskData) => (item: unknown, monitor: DropTargetMonitor) => void;
    hasTaskIcon?: boolean;
    extraMargin?: number;
}


function TaskGrid({ containerWidth, containerHeight, dragging, setDragging, filterRule, showUtilityCard, dropRule, hasTaskIcon, extraMargin }: TaskGridProps) {
    const navigate = useNavigate();
    const { activeProject } = useProjectsContext();
    const projectId = activeProject?.id ?? null;
    const { filter, order } = useAppContext();
    const { mutate: updateTask } = useUpdateTask();
    const { data: tasks, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage } = useTasks(projectId, order, filter, filterRule);
    const tasksLength = tasks?.pages.reduce((acc, num) => acc + num.data.length, 0) ?? 0;

    const handleUtilClick = useCallback(() => {
        navigate("/app/tasks/create");
    }, [navigate]);

    const handleUtilDrop = useCallback((card: CardData) => {
        if (isTaskData(card) && card.abstractState !== "closed" && projectId) {
            updateTask({ projectId, taskId: card.id, data: "close" });
            setDragging(null);
        } else if (isTaskData(card) && card.abstractState === "closed" && projectId) {
            updateTask({ projectId, taskId: card.id, data: "archive" });
            setDragging(null);
        }
    }, [projectId, setDragging, updateTask]);

    const handleClick = useCallback((taskId: string) => {
        navigate(`/app/tasks/id/${taskId}`);
    }, [navigate]);

    const [utilIcon, setUtilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const [utilText, setUtilText] = useState("Create Task");

    useEffect(() => {
        if (dragging) {
            if (dragging.abstractState !== "closed") {
                setUtilIcon(<FaRegWindowClose className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
                setUtilText("Close Task");
            } else {
                setUtilIcon(<FaArchive className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
                setUtilText("Archive Task");
            }
        } else {
            setUtilIcon(<FaPlus className="ml-2 mr-2" />);
            setUtilText("Create Task");
        }
    }, [dragging]);

    const loadMoreItems = async () => {
        if (!isFetchingNextPage) {
            return fetchNextPage().then(() => Promise.resolve());
        }
        return Promise.resolve();
    };
    const isItemLoaded = useCallback((index: number): boolean => {
        return index < tasksLength || !hasNextPage;
    }, [tasksLength, hasNextPage]);

    let height = containerHeight - BOTTOM_MARGIN;
    if (showUtilityCard) {
        height -= UTILITY_MARGIN;
    }
    if (extraMargin) {
        height -= extraMargin;
    }
    const width = Math.round(containerWidth);
    const items = tasks ? tasks.pages.flatMap(page => page.data) : [];

    const itemData = {
        items,
        dragging,
        handleClick,
        setDragging,
        dropRule,
        hasTaskIcon,
    };

    if (isError) {
        return <div className="flex justify-center items-center h-full">Error loading tasks</div>;
    }
    const skeleton = <Skeleton style={{
        width: `${width - 20 * 2}px`,
        height: `${height - 20 * 2}px`,
        margin: "20px"
    }
    } />;

    return (
        <div className="flex-col">
            {showUtilityCard && <UtilityCard
                handleCardClick={handleUtilClick}
                handleCardDrop={handleUtilDrop}
                dragging={dragging}
                icon={utilIcon}
                style={{ height: "64px", width: "calc(100% - 32px)" }}
                cardText={utilText}
                showSort={true}
            />}
            {isLoading ? skeleton :
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={hasNextPage ? tasksLength + 1 : tasksLength}
                    loadMoreItems={loadMoreItems}
                >
                    {({ onItemsRendered, ref }) => (
                        <FixedSizeList
                            className="scroll-list"
                            height={height}
                            itemSize={128}
                            width={width}
                            itemData={itemData}
                            itemCount={tasksLength}
                            onItemsRendered={onItemsRendered}
                            ref={ref} >
                            {Card}
                        </FixedSizeList>
                    )}
                </InfiniteLoader>}
        </div>
    );
}

export default TaskGrid;
