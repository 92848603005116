import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $convertToMarkdownString } from '@lexical/markdown';
import { $isBeautifulMentionNode, BeautifulMentionNode } from 'lexical-beautiful-mentions';
import { TextMatchTransformer, TRANSFORMERS } from '@lexical/markdown';
import { USER_CACHE } from "@/contexts/User";
import { LexicalNode } from "lexical";
import { Button } from '@/components/ui/button';
import { FaRegSave } from 'react-icons/fa';

interface MarkdownActionsProps {
    onSaveMarkdown(markdown: string): void;
}

const USER_ID_REGEX = /@\{([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\}/;

const MARKDOWN: TextMatchTransformer = {
    dependencies: [BeautifulMentionNode],
    export: (node: LexicalNode) => {
        if (!$isBeautifulMentionNode(node)) {
            return null;
        }
        const beautifulNode = node as BeautifulMentionNode;
        const data = beautifulNode.getData();
        if (!data) return '';
        return `@{${data.id}}`;
    },
    importRegExp: USER_ID_REGEX,
    regExp: USER_ID_REGEX,
    replace: (parentNode, match) => {
        const [, userId] = match;
        const user = USER_CACHE.getUser(userId);
        let nodeData;
        if (user) {
            nodeData = { id: userId, value: user.username };
        } else {
            nodeData = { id: userId, value: 'Unknown User' };
        }
        const mentionNode = new BeautifulMentionNode('@', nodeData.value, nodeData);
        parentNode.replace(mentionNode);
    },
    trigger: '}',
    type: 'text-match',
};

export const TRANSFORMERS_PLUS = [...TRANSFORMERS, MARKDOWN];

export const MarkdownSave = ({ onSaveMarkdown }: MarkdownActionsProps) => {
    const [editor] = useLexicalComposerContext();

    const handleOnClick = () => {
        editor.update(() => {
            const markdown = $convertToMarkdownString(TRANSFORMERS_PLUS);
            onSaveMarkdown(markdown);
        });
    };

    return (
        <Button className="flex items-center bg-neutral-300 dark:bg-neutral-700 m-1 mx-2 dark:hover:bg-aquamarine-400" onClick={handleOnClick}>
            <FaRegSave className="mr-1" />
            Save
        </Button>
    );
};
