import { DownloadIcon } from 'lucide-react';
import { Routes, Route, Link } from 'react-router-dom'
import { Description } from '@radix-ui/react-dialog';
import { createRef, useCallback, useEffect, useState } from 'react';

import AppPage from '@/components/AppPage';
import ProjectDetailsView from './ProjectDetailsView';
import ProjectGrid from './ProjectGrid';
import { Button } from '@/components/ui/button';
import { CardData, isProjectData } from '@/components/cards/CardDataInterfaces';
import { Dialog, DialogClose, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Project } from '@/contexts/Project';
import { isOrgAdmin } from '@/contexts/User';
import { useAppContext, useProjectsContext } from '@/contexts';

function ProjectsView() {
    const { width, height, loggedInUser } = useAppContext();
    const {
        projects,
        orgProjects,
        moveProjectToOrg
    } = useProjectsContext();

    const personalDivRef = createRef<HTMLDivElement>();
    const teamDivRef = createRef<HTMLDivElement>();

    const [personalWidth, setPersonalWidth] = useState(width);
    const [personalHeight, setPersonalHeight] = useState(height);
    const [teamWidth, setTeamWidth] = useState(width);
    const [teamHeight, setTeamHeight] = useState(height);
    const [moveCardData, setMoveCardData] = useState<Project | null>(null);
    const [moveDialogOpen, setMoveDialogOpen] = useState(false);
    const [dragging, setDragging] = useState<Project | null>(null);

    useEffect(() => {
        if (personalDivRef.current) {
            const rect = personalDivRef.current.getBoundingClientRect();
            setPersonalWidth(rect.width);
            setPersonalHeight(rect.height);
        }
        if (teamDivRef.current) {
            const rect = teamDivRef.current.getBoundingClientRect();
            setTeamWidth(rect.width);
            setTeamHeight(rect.height);
        }
    }, [personalDivRef, teamDivRef, width, height]);

    const admin = isOrgAdmin(loggedInUser);

    const handleMoveToOrg = (project: CardData) => {
        if (isProjectData(project)) {
            setMoveCardData(project);
            setMoveDialogOpen(true);
        }
    }

    const onDialogConfirmMove = useCallback(() => {
        if (moveCardData) {
            moveProjectToOrg(moveCardData);
        }
    }, [moveCardData, moveProjectToOrg]);

    return (
        <div className="flex p-6 w-full">
            <div className='w-1/2 max-w-lg' id="personalProjectGrid" ref={personalDivRef}>
                <h2 className="text-center">Personal</h2>
                {admin && <div className="flex m-4 h-8"></div>}
                <ProjectGrid projects={projects} containerWidth={personalWidth} containerHeight={personalHeight} projectSource="personal" dragging={dragging} setDragging={setDragging} />
            </div>
            <div className='w-1/2 max-w-lg' id="teamProjectGrid" ref={teamDivRef}>
                <h2 className="text-center">Team</h2>
                {admin && <div className="flex m-4 h-8">
                    <Link to="/app/import"><Button className="btn"><DownloadIcon className='mr-2' />Import</Button></Link>
                </div>}
                <ProjectGrid projects={orgProjects} containerWidth={teamWidth} containerHeight={teamHeight} projectSource="organization" dropRule={handleMoveToOrg} dragging={dragging} setDragging={setDragging} />
            </div>
            <Dialog open={moveDialogOpen} onOpenChange={setMoveDialogOpen}>
                <DialogContent>
                    <DialogTitle>Move project</DialogTitle>
                    <Description>Are you sure you want to move this project to your team?<br />This cannot be undone.</Description>
                    <DialogClose className="flex">
                        <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black" onClick={onDialogConfirmMove}>Yes</Button>
                        <Button className="editable__button">No</Button>
                    </DialogClose>
                </DialogContent>
            </Dialog>
        </div>
    )
}

function ProjectWindow() {
    return (
        <AppPage active="projects">
            <Routes>
                <Route path="/" element={<ProjectsView />} />
                <Route path=":id" element={<ProjectDetailsView />} />
            </Routes>
        </AppPage>
    );
}

export default ProjectWindow;
