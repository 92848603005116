import { createRef, useCallback, useEffect, useState } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { FaArchive, FaPlus } from 'react-icons/fa';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Description } from "@radix-ui/react-dialog";

import AppPage from '@/components/AppPage';
import MilestoneDetailsView from '@/components/cards/milestone/MilestoneDetailsView';
import UtilityCard from "@/components/cards/UtilityCard.tsx";
import { Button } from "@/components/ui/button";
import { Card } from '@/components/cards/milestone/MiniMilestone';
import { CardData } from "@/components/cards/CardDataInterfaces";
import { Dialog, DialogClose, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Milestone } from '@/contexts/Milestone.tsx';
import { useAppContext, useMilestoneContext } from '@/contexts';

const BOTTOM_MARGIN = 148;

const MilestoneGrid = () => {
    const { width, height } = useAppContext();
    const { milestones, hasNextPage, loadNextPage, deleteMilestone } = useMilestoneContext();
    const navigate = useNavigate();

    const handleUtilClick = useCallback(() => {
        navigate("/app/milestones/create");
    }, [navigate]);

    const isItemLoaded = useCallback((index: number): boolean => {
        return index < milestones.length;
    }, [milestones.length]);

    const outerDivRef = createRef<HTMLDivElement>();

    const [contentHeight, setHeight] = useState(height);
    const [contentWidth, setWidth] = useState(width);

    useEffect(() => {
        if (outerDivRef.current) {
            const rect = outerDivRef.current.getBoundingClientRect();
            setHeight(rect.height - BOTTOM_MARGIN);
            setWidth(rect.width);
        }
    }, [outerDivRef, width, height]);

    const createText = "Create Milestone"
    const [utilIcon, setUtilIcon] = useState(<FaPlus className="ml-2 mr-2" />);
    const [utilText, setUtilText] = useState(createText);
    const [dragging, setDragging] = useState<Milestone | null>(null);

    useEffect(() => {
        if (dragging) {
            setUtilIcon(<FaArchive className="text-rufous-600 dark:text-hellotrope-400 ml-2 mr-2" />);
            setUtilText("Remove Milestone");
        } else {
            setUtilIcon(<FaPlus className="ml-2 mr-2" />);
            setUtilText(createText);
        }
    }, [dragging]);

    const itemData = {
        items: milestones,
        dragging,
        setDragging,
        handleClick: (id: string) => navigate(`/app/milestones/${id}`),
        dropRule: () => { }
    };

    const [deleteCardData, setDeleteCardData] = useState<Milestone | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleUtilDrop = useCallback((card: CardData) => {
        const milestone = card as Milestone;
        setDeleteDialogOpen(true);
        setDeleteCardData(milestone);
    }, []);

    const onDialogConfirmDelete = useCallback(() => {
        if (deleteCardData) {
            deleteMilestone(deleteCardData.id);
            setDeleteCardData(null);
        }
    }, [deleteCardData, deleteMilestone]);

    return <div className="flex-col max-w-lg" ref={outerDivRef}>
        <h1 className="text-2xl font-bold ml-4">Milestones</h1>
        <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
            <DialogContent>
                <DialogTitle>Milestone deletion</DialogTitle>
                <Description>Are you sure you want to delete this milestone?<br />This cannot be undone.</Description>
                <DialogClose className="flex">
                    <Button className="editable__button bg-red-600 dark:bg-hellotrope-400 text-white dark:text-black" onClick={onDialogConfirmDelete}>Yes</Button>
                    <Button className="editable__button">No</Button>
                </DialogClose>
            </DialogContent>
        </Dialog>
        <UtilityCard
            handleCardClick={handleUtilClick}
            handleCardDrop={handleUtilDrop}
            dragging={dragging}
            icon={utilIcon}
            cardText={utilText}
            style={{ height: 100, width: "calc(100% - 32px)" }}
        />
        <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={hasNextPage ? milestones.length + 1 : milestones.length}
            loadMoreItems={loadNextPage}
        >
            {({ onItemsRendered, ref }) => (
                <FixedSizeList
                    height={contentHeight}
                    width={contentWidth}
                    className='scroll-list'
                    itemSize={128}
                    itemData={itemData}
                    itemCount={milestones.length}
                    onItemsRendered={onItemsRendered}
                    ref={ref}>
                    {Card}
                </FixedSizeList>
            )}
        </InfiniteLoader>
    </div>;

}

const MilestoneDetailsRoute = () => {
    const { narrow } = useAppContext();
    return (<>
        {!narrow && <MilestoneGrid />}
        <MilestoneDetailsView />
    </>);
}

const MilestonePage: React.FC = () => {
    return <AppPage active="milestones">
        <Routes>
            <Route path="/" element={<MilestoneGrid />} />
            <Route path=":id" element={<MilestoneDetailsRoute />} />
        </Routes>
    </AppPage>;
};
export default MilestonePage;
