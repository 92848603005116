import { FaXmark } from "react-icons/fa6";
import { FaProjectDiagram } from "react-icons/fa";
import React, { useState } from "react";
import Personelle from "../../Personelle";
import { useAppContext, useProjectsContext, useUsersContext } from "../../../contexts";
import { ProjectSource } from "../../../contexts/Project";
import DetailGrid, { DetailContentArea, } from "../DetailGrid";
import { useNavigate, useSearchParams } from "react-router-dom";
import Editor from "@/components/Editor";

function ProjectCreate() {
    const { loggedInUser } = useAppContext();
    const { addProject } = useProjectsContext();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { users } = useUsersContext();
    const modalRef = React.createRef<HTMLDivElement>();
    const [name, setName] = useState("");

    if (loggedInUser == null) {
        return null;
    }

    const vanish = () => {
        navigate(-1);
    };

    function saveProject(markdown: string) {
        const projectSource = searchParams.get("projectSource") as ProjectSource | null;
        addProject({
            name: name,
            description: markdown,
            projectSource
        });
        vanish();
    }

    return (
        <div ref={modalRef} className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <FaProjectDiagram />
                </div>
                <div className="flex-grow" />
                <div className="card-detail__header-container">
                    <h2 className="card-detail__header">Create a Project</h2>
                </div>
                <div className="flex-grow" />
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <DetailGrid narrow={true}>
                <DetailContentArea>
                    <div className="onboarding__form">
                        <label id="nameLabel" className="onboarding__label">Project Name</label>
                        <input id="name" autoFocus className="onboarding__input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="justify-center flex flex-col items-center">
                        <label id="description_label" className="onboarding__label mt-2">Project Description</label>
                        <Editor
                            markdown=""
                            placeholder="Describe your project..."
                            users={users}
                            onSaveMarkdown={saveProject}
                        />
                    </div>
                    <div className="p-2">
                        <Personelle userData={loggedInUser} tag="author" />
                    </div>
                </DetailContentArea>
            </DetailGrid>
        </div>
    );
}

export default ProjectCreate;
